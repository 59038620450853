import { Button, Modal } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const ExtraMatchScoreSubmitedModal = ({open, setOpen}) => {
   
      
  return (
    <Modal open={open}    >
        <div className='flex justify-center mt-44'>
        <div className='bg-white border-0 py-4 px-4 lg:w-[40vw] mx-4 lg:mx-0 '>
            <div className='text-center text-[#06B3D2] text-[22px] font-[600] ' >Scores Submitted</div>
            <div className='text-center text-[18px] text-[#244F62D9] '>
            We’ve got your scores and are waiting for confirmation from the other team. Once the accept, the scores will be live.
            </div>

            <div className=" flex justify-center mt-[50px] ">
               
               <Button
                 sx={{
                   bgcolor: "#44A093",
                   color: "white",
                   width: "150px",
                   textTransform: "none",
                   borderRadius: "5px",
                   fontSize: "15px",
                   ":hover": {
                     bgcolor: "#44A093",
                   },
                 }}
                 onClick={()=> {
                    window.location.pathname ='/dashboard/my-matches'
                 }}
               >
                 Return home
               </Button>
           </div>
        </div>
        </div>
    </Modal>
  )
}

export default ExtraMatchScoreSubmitedModal