import {
  Avatar,
  AvatarGroup,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Modal,
  Switch,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import ReactHelmet from "react-helmet";
import { Add, ArrowRight, Edit, KeyboardArrowRight } from "@mui/icons-material";
import Snack from "../components/Snack";
import SnackRed from "../components/SnackRed";

const Profile = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [email_modal, setEmailModal] = useState(false);
  const [name_modal, setNameModal] = useState(false);
  const [phone_modal, setPhoneModal] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [openSnackRed, setOpenSnackRed] = useState(false);
  const [snackredmsg, setSnackRedMsg] = useState("");

  const navigate = useNavigate();
  const updateProfile = async () => {
    if (user.phone_number && user.phone_number.length > 0 && user.phone_number.length < 14) {
      setOpenSnackRed(true);
      setSnackRedMsg("please input a valid phone number");
    }
    else{
      await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/update-profile/", user)
      .then((res) => {
        console.log(res.data);
        setUser(res.data.user);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        console.log(res.data);
        setSnackMsg("user profile updated");
        setOpenSnack(true);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const avatarStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <div className="bg-white rounded-[30px]   overflow-auto lg:w-[700px] w-full px-4 my-8  pb-5 text-[#244F62]">
      <ReactHelmet>
        <title>Profile - Paddle Party</title>
      </ReactHelmet>
      <Snack open={openSnack} setOpen={setOpenSnack} msg={snackMsg} />
      <SnackRed
        open={openSnackRed}
        setOpen={setOpenSnackRed}
        msg={snackredmsg}
      />
      <EmailModal
        open={email_modal}
        setOpen={setEmailModal}
        user={user}
        setUser={setUser}
      />

      <NameModal
        open={name_modal}
        setOpen={setNameModal}
        user={user}
        setUser={setUser}
      />

      <PhoneModal
        open={phone_modal}
        setOpen={setPhoneModal}
        user={user}
        setUser={setUser}
      />

      <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
        Profile
      </div>

      <div className="flex justify-center">
        <Avatar src={user?.avatar} sx={{ width: "100px", height: "100px" }} />
      </div>

      <div className="flex justify-center">
        <div className="font-bold text-[18px] flex items-center space-x-2">
          {" "}
          <div>{user?.first_name + " " + user?.last_name} </div>{" "}
          <img
            src="/edit.png"
            className="w-[16px]"
            onClick={(e) => {
              setNameModal(true);
            }}
          />{" "}
        </div>
      </div>

      <div className="space-y-4 mt-2">
        <div>
          <div className="font-[400] text-[16px]">Information</div>
          <Divider sx={{ my: 2 }} />
        </div>

        <div>
          <div className="flex items-center ">
            <div className="font-[400] text-[18px] flex-1">
              Email:{" "}
              <span className="font-semibold" id="name">
                {user?.email}
              </span>
            </div>
            {/* <img
              src="/edit.png"
              onClick={(e) => {
                setEmailModal(true);
              }}
              className="w-[16px]"
            /> */}
          </div>
          <Divider sx={{ my: 2 }} />
        </div>

        <div>
          <div className="flex items-center ">
            <div className="font-[400] text-[18px] flex-1">
              Phone: <span className="font-semibold">{user.phone_number}</span>
            </div>
            <img
              src="/edit.png"
              className="w-[16px]"
              onClick={(e) => {
                setPhoneModal(true);
              }}
            />
          </div>
          <Divider sx={{ my: 2 }} />
        </div>
      </div>

      <div className="space-y-4 mt-9">
        <div>
          <div className="font-[400] text-[16px]">Notification Preferences</div>
          <Divider sx={{ my: 2 }} />
        </div>

        <div>
          <div className="flex items-center space-x-2 ">
            <div className="font-[600] text-[18px] w-full ">Alert Types:</div>
            <TextField
              value={user?.alert_type}
              select
              variant="outlined"
              fullWidth
              sx={{
                border: "1px solid #44A093",
                bgcolor: "rgba(68, 160, 147, 0.02)",
                borderRadius: "5px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-root": {
                  padding: "1px 2px",
                },
              }}
              onChange={(e) => {
                setUser({ ...user, alert_type: e.target.value });
              }}
            >
              <MenuItem value="small_alerts">Smart Alerts</MenuItem>
              <MenuItem value="all_proposal">All Proposals</MenuItem>
              <MenuItem value="no_proposal">No Proposals</MenuItem>
            </TextField>
          </div>
          <Divider sx={{ my: 2 }} />
        </div>

       {user.league_data.sms_enabled && (
         <div>
         <div className="flex items-center ">
           <div className="font-[600] text-[18px] flex-1 ">
             SMS Notifications:{" "}
           </div>
           <Switch
             checked={user?.sms_preference}
             onChange={(e) => {
               if (e.target.checked) {
                 setUser({ ...user, sms_preference: true });
               } else {
                 setUser({ ...user, sms_preference: false });
               }
             }}
             sx={{
               "& .MuiSwitch-switchBase.Mui-checked": {
                 color: "#12AFC2",
                 "&:hover": {
                   backgroundColor: "rgba(18, 175, 194, 0.08)",
                 },
               },
               "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                 backgroundColor: "#12AFC233",
               },
               "& .MuiSwitch-track": {
                 backgroundColor: "#12AFC266", // Darker shade for unchecked state
               },
             }}
           />
         </div>
         <Divider sx={{ my: 2 }} />
       </div>
       )}

        <div>
          <div className="flex items-center ">
            <div className="font-[600] text-[18px] flex-1">
              Email Notifications:{" "}
            </div>
            <Switch
              checked={user?.email_preference}
              onChange={(e) => {
                if (e.target.checked) {
                  setUser({ ...user, email_preference: true });
                } else {
                  setUser({ ...user, email_preference: false });
                }
              }}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#12AFC2",
                  "&:hover": {
                    backgroundColor: "rgba(18, 175, 194, 0.08)",
                  },
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#12AFC233",
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "#12AFC266", // Darker shade for unchecked state
                },
              }}
            />
          </div>
          <Divider sx={{ my: 2 }} />
        </div>
      </div>

      <div className=" flex justify-center mt-[50px] ">
        <Button
          sx={{
            bgcolor: "#44A093",
            color: "white",
            width: "150px",
            textTransform: "none",
            borderRadius: "5px",
            fontSize: "15px",
            ":hover": {
              bgcolor: "#44A093",
            },
          }}
          onClick={updateProfile}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const EmailModal = ({ open, setOpen, user, setUser }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="bg-white mt-44 flex justify-center mx-4 py-4 lg:mx-[500px]">
        <div>
          <div className="mb-3">
            {" "}
            <div>Email</div>
            <InputBase
              sx={{ border: "0.5px solid gray", borderRadius: "5px", px: 2 }}
              value={user?.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
          </div>
          <div className="flex items-center justify-center">
            <Button
              sx={{
                bgcolor: "#44A093",
                color: "white",
                width: "150px",
                textTransform: "none",
                borderRadius: "5px",
                fontSize: "15px",
                ":hover": {
                  bgcolor: "#44A093",
                },
              }}
              onClick={() => setOpen(false)}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const NameModal = ({ open, setOpen, user, setUser }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="bg-white mt-44 flex justify-center mx-4 py-4 lg:mx-[500px]">
        <div>
          <div className="mb-3">
            {" "}
            <div>Firstname</div>
            <InputBase
              sx={{ border: "0.5px solid gray", borderRadius: "5px", px: 2 }}
              value={user?.first_name}
              onChange={(e) => setUser({ ...user, first_name: e.target.value })}
            />
          </div>

          <div className="mb-3">
            {" "}
            <div>Lastname</div>
            <InputBase
              sx={{ border: "0.5px solid gray", borderRadius: "5px", px: 2 }}
              value={user?.last_name}
              onChange={(e) => setUser({ ...user, last_name: e.target.value })}
            />
          </div>
          <div className="flex items-center justify-center">
            <Button
              sx={{
                bgcolor: "#44A093",
                color: "white",
                width: "150px",
                textTransform: "none",
                borderRadius: "5px",
                fontSize: "15px",
                ":hover": {
                  bgcolor: "#44A093",
                },
              }}
              onClick={() => setOpen(false)}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const PhoneModal = ({ open, setOpen, user, setUser }) => {
  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, ""); // Remove all non-digit characters
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setUser({ ...user, phone_number: formattedPhoneNumber });
  };

  const [errorMsg, setErrorMsg] = useState();

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="bg-white mt-44 flex justify-center mx-4 py-4 lg:mx-[500px]">
        <div>
          <div className="mb-3">
            <div>Phone Number</div>
            <InputBase
              inputProps={{ inputMode: "tel" }}
              type="text" // Change to 'text' to allow formatting
              placeholder="(123) 456-7890"
              sx={{ border: "0.5px solid gray", borderRadius: "5px", px: 2 }}
              value={user?.phone_number}
              onChange={handlePhoneNumberChange}
            />
          </div>
          {errorMsg && errorMsg.length > 1 && (
            <div className="text-[13px] text-center my-2 text-red-700">
              {" "}
              {errorMsg}{" "}
            </div>
          )}
          <div className="flex items-center justify-center">
            <Button
              sx={{
                bgcolor: "#44A093",
                color: "white",
                width: "150px",
                textTransform: "none",
                borderRadius: "5px",
                fontSize: "15px",
                ":hover": {
                  bgcolor: "#44A093",
                },
              }}
              onClick={(e) => {
                if (user.phone_number.length < 14) {
                  setErrorMsg("Please enter valid phone number");
                } else {
                  setOpen(false);
                  setErrorMsg("");
                }
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default Profile;
