import { Home } from "@mui/icons-material";
import { Avatar, AvatarGroup, Button, Divider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import ReactHelmet from "react-helmet";

const MatchDetails = () => {
  const { match_id } = useParams();
  const [match, setMatch] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [team1, setTeam1] = useState();
  const [team2, setTeam2] = useState();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const getMatch = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-match/" + match_id)
      .then((res) => {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        if (res.data.success) {
          console.log(res.data.match);
          setMatch(res.data.match);
          getTeam1(res.data.match.teams[0].team_id);
          getTeam2(res.data.match.teams[1].team_id);
        } else {
          setErrorMsg(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTeam1 = async (team_id) => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-team/" + team_id)
      .then((res) => {
        setTeam1(res.data.team);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTeam2 = async (team_id) => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-team/" + team_id)
      .then((res) => {
        setTeam2(res.data.team);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMatch();
  }, []);

  const getUser = async (user_id) => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-user/" + user_id)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMatch();
  }, []);

  return (
    <>
      <ReactHelmet>
        <title>Match Details - Paddle Party</title>
      </ReactHelmet>
      {!loader ? (
        <>
          {errorMsg ? (
            <div>{errorMsg}</div>
          ) : (
            <div className="bg-white rounded-[30px] lg:w-[700px] px-4 my-8  ">
              <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
                Match Details
              </div>

              <div className=" lg:text-[24px] text-[16px] text-center my-3 text-[#244F62D9] font-[400]">
                {match.match_name}
              </div>

              <div className=" lg:text-[20px] text-[14px] text-center my-3 text-[#244F62D9] font-[400]">
                {match.week_name}
                {/* :{match.match_date || " no match date"} */}
              </div>
              {user.league_data.on_demand ? (
                <>
                  {!match?.score ? (
                    <div className="flex justify-center space-x-2 mt-4 mb-9">
                      <div className="  ">
                        <Button
                          sx={{
                            bgcolor: "rgba(18,175,194,0.03)",
                            color: "rgba(36,79,98,0.9)",
                            width: "100%",
                            textTransform: "none",
                            borderRadius: "23px",
                            border: "1px solid #12AFC2",
                            fontSize: { lg: "20px", xs: "14px" },
                            px: { lg: 4, xs: 1 },
                          }}
                          onClick={() => {
                            navigate("/dashboard/submit-scores/" + match_id);
                          }}
                        >
                          <img
                            src="/submit.png"
                            className="w-[19px] h-[19px] mr-2"
                          />
                          Submit Scores
                        </Button>
                      </div>

                      <div className="  ">
                        <Button
                          sx={{
                            bgcolor: "rgba(18,175,194,0.03)",
                            color: "rgba(36,79,98,0.9)",
                            width: "100%",
                            textTransform: "none",
                            borderRadius: "23px",
                            border: "1px solid #12AFC2",
                            fontSize: { lg: "20px", sm: "14px" },
                            px: { lg: 4, xs: "14px" },
                          }}
                          onClick={() => {
                            window.open(match.chat_url, "_blank");
                          }}
                        >
                          <img
                            src="/chat.png"
                            className="w-[19px] h-[19px] mr-2"
                          />
                          Match Chat
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="border-[1px] border-[#12AFC2] bg-[rgba(18,175,194,0.03)] rounded-[20px] lg:mx-28 py-2">
                      <div className="flex justify-center items-center text-[18px] text-[#244F62E5] font-[700] mb-3   ">
                        <img
                          src="/submit.png"
                          className="h-[15px] w-[22px] pr-2 "
                        />{" "}
                        Match Results
                      </div>

                      {match &&
                        match.score &&
                        match.score.map((item, index) => {
                          let otherTeamId = Object.keys(item.game1).find(
                            (teamId) =>
                              teamId !=( match?.home_team ||
                              match?.teams[0].team_id)
                          );
                          let homeTeamId =
                            (match?.home_team || match?.teams[0].team_id);
                          return (
                            <>
                              <div className="text-[#45697A] text-[18px] flex justify-center items-center">
                                <span className="font-bold mr-3 ">Game 1</span>{" "}
                                {item.game1[otherTeamId]}-
                                {item.game1[homeTeamId]} (
                                {item.game1[otherTeamId] >
                                  item.game1[homeTeamId] &&
                                  match.teams.find(
                                    (e) => e.team_id == otherTeamId
                                  ).team_name}
                                {item.game1[homeTeamId] >
                                  item.game1[otherTeamId] &&
                                  match.teams.find(
                                    (e) => e.team_id == homeTeamId
                                  ).team_name}
                                )
                              </div>

                              <div className="text-[#45697A] text-[18px] flex justify-center items-center">
                                <span className="font-bold mr-3">Game 2</span>{" "}
                                {item.game2[otherTeamId]}-
                                {item.game2[homeTeamId]} (
                                {item.game2[otherTeamId] >
                                  item.game2[homeTeamId] &&
                                  match.teams.find(
                                    (e) => e.team_id == otherTeamId
                                  ).team_name}
                                {item.game2[homeTeamId] >
                                  item.game2[otherTeamId] &&
                                  match.teams.find(
                                    (e) => e.team_id == homeTeamId
                                  ).team_name}
                                )
                              </div>

                              {item.game3 && (
                                <div className="text-[#45697A] text-[18px] flex justify-center items-center">
                                  <span className="font-bold mr-3">Game 3</span>{" "}
                                  {item.game3[otherTeamId]}-
                                  {item.game3[homeTeamId]} (
                                  {item.game3[otherTeamId] >
                                    item.game3[homeTeamId] &&
                                    match.teams.find(
                                      (e) => e.team_id == otherTeamId
                                    ).team_name}
                                  {item.game3[homeTeamId] >
                                    item.game3[otherTeamId] &&
                                    match.teams.find(
                                      (e) => e.team_id == homeTeamId
                                    ).team_name}
                                  )
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-center space-x-2 mt-4 mb-9">
                  <div className="  ">
                    <Button
                      sx={{
                        bgcolor: "rgba(18,175,194,0.03)",
                        color: "rgba(36,79,98,0.9)",
                        width: "100%",
                        textTransform: "none",
                        borderRadius: "23px",
                        border: "1px solid #12AFC2",
                        fontSize: { lg: "20px", xs: "14px" },
                        px: { lg: 4, xs: 1 },
                      }}
                      onClick={() => {
                        navigate("/dashboard/submit-scores/" + match_id);
                      }}
                    >
                      <img
                        src="/submit.png"
                        className="w-[19px] h-[19px] mr-2"
                      />
                      Submit Scores
                    </Button>
                  </div>

                  <div className="  ">
                    <Button
                      sx={{
                        bgcolor: "rgba(18,175,194,0.03)",
                        color: "rgba(36,79,98,0.9)",
                        width: "100%",
                        textTransform: "none",
                        borderRadius: "23px",
                        border: "1px solid #12AFC2",
                        fontSize: { lg: "20px", sm: "14px" },
                        px: { lg: 4, xs: "14px" },
                      }}
                      onClick={() => {
                        window.open(match.chat_url, "_blank");
                      }}
                    >
                      <img src="/chat.png" className="w-[19px] h-[19px] mr-2" />
                      Match Chat
                    </Button>
                  </div>
                </div>
              )}
              <div className=" lg:overflow-y-auto">
                <div>
                  <div className="flex justify-center">
                    <div className=" flex items-center space-x-4  lg:text-[24px] text-[16px]  my-3 text-[#244F62D9] font-[400]">
                      <div
                        className="font-bold cursor-pointer"
                        onClick={() => {
                          navigate("/dashboard/team/" + match.teams[0].team_id);
                        }}
                      >
                        {" "}
                        {match?.teams[0].team_name.slice(0, 14)}{" "}
                        {!team1?.matches_ || team1?.matches_.length < 1
                          ? "UR"
                          : null}
                      </div>
                      <div className="flex items-center">
                        <img
                          src="/more.png"
                          className="w-[19px] h-[19px] mr-2 cursor-pointer"
                          onClick={() => {
                            navigate("/dashboard/team/" + team1?._id);
                          }}
                        />

                        <div className="col-span-2 lg:text-[14px] text-[12px] text-white font-[500]">
                          {match.home_team === match.teams[0].team_id && (
                            <div className="bg-[#44A093] w-fit rounded-[5px] px-4 ">
                              {" "}
                              <Home
                                sx={{
                                  fontSize: "14px",
                                  position: "relative",
                                  bottom: "1px",
                                }}
                              />{" "}
                              <span className="hidden lg:block">Home</span>
                            </div>
                          )}
                          <div className="col-span-2 lg:text-[14px] text-[12px] text-white font-[500]">
                            <div className=" lg:w-[80px] rounded-[5px] px-4 "></div>
                          </div>
                        </div>
                      </div>
                      <div className="text-[18px]">
                        {match?.score &&
                          match?.score.map((item, index) => {
                            let wins = 0;
                            let losses = 0;
                            let team_id = match.teams[0].team_id;
                            let otherTeamId = Object.keys(item.game1).find(
                              (teamId) => teamId != team_id
                            );
                            if (item.game1) {
                              if (
                                item.game1[team_id] > item.game1[otherTeamId]
                              ) {
                                wins = wins + 1;
                              } else {
                                losses = losses + 1;
                              }
                            }

                            if (item.game2) {
                              if (
                                item.game2[team_id] > item.game2[otherTeamId]
                              ) {
                                wins = wins + 1;
                              } else {
                                losses = losses + 1;
                              }
                            }

                            if (item.game3) {
                              if (
                                item.game3[team_id] > item.game3[otherTeamId]
                              ) {
                                wins = wins + 1;
                              } else {
                                losses = losses + 1;
                              }
                            }

                            return wins + "W - " + losses + "L";
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-center">
                    <div>
                      <div className="flex justify-center">
                        <Avatar
                          alt={team1?.player1.first_name}
                          src={team1?.player1.avatar || "/generic.jpeg"}
                          sx={{
                            width: { lg: "100px", xs: "60px" },
                            height: { lg: "100px", xs: "60px" },
                          }}
                        />
                      </div>

                      <div className="lg:text-[24px] text-[16px]  text-center my-3 text-[#244F62D9] font-[5Ï00]">
                        {team1?.player1.first_name +
                          " " +
                          team1?.player1.last_name}
                      </div>
                    </div>

                    <Divider
                      sx={{
                        borderBottom: "2px solid #12AFC2",
                        width: "30px",
                        position: "relative",
                        bottom: 24,
                        mx: 4,
                      }}
                    />
                    <div>
                      <div className="flex justify-center">
                        <Avatar
                          alt={team1?.player2.first_name}
                          src={team1?.player2.avatar || "/generic.jpeg"}
                          sx={{
                            width: { lg: "100px", xs: "60px" },
                            height: { lg: "100px", xs: "60px" },
                          }}
                        />
                      </div>

                      <div className=" lg:text-[24px] text-[16px]  text-center my-3 text-[#244F62D9] font-[5Ï00]">
                        {team1?.player2.first_name +
                          " " +
                          team1?.player2.last_name}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="flex justify-center">
                    <div className=" flex items-center space-x-4  lg:text-[24px] text-[16px]  my-3 text-[#244F62D9] font-[400]">
                      <div
                        className="font-bold cursor-pointer"
                        onClick={() => {
                          navigate("/dashboard/team/" + match.teams[1].team_id);
                        }}
                      >
                        {" "}
                        {match.teams[1].team_name.slice(0, 14)}{" "}
                        {!team2?.matches_ || team2?.matches_.length < 1
                          ? "UR"
                          : null}
                      </div>
                      <div className="flex items-center">
                        <img
                          src="/more.png"
                          className="w-[19px] h-[19px] mr-2 cursor-pointer"
                          onClick={() => {
                            navigate("/dashboard/team/" + team2?._id);
                          }}
                        />

                        <div className="col-span-2 lg:text-[14px] text-[12px] text-white font-[500]">
                          {match.home_team === match.teams[1].team_id && (
                            <div className="bg-[#44A093] w-[fit] rounded-[5px] px-4 flex items-center ">
                              {" "}
                              <Home
                                sx={{
                                  fontSize: "14px",
                                  position: "relative",
                                  bottom: "1px",
                                }}
                              />{" "}
                              <span className="hidden lg:block">Home</span>
                            </div>
                          )}
                        </div>

                        <div className="col-span-2 lg:text-[14px] text-[12px] text-white font-[500]">
                          <div className=" lg:w-[80px] rounded-[5px] px-4 "></div>
                        </div>
                      </div>
                      <div className="text-[18px]">
                        {match?.score &&
                          match?.score.map((item, index) => {
                            let wins = 0;
                            let losses = 0;
                            let team_id = match.teams[1].team_id;
                            let otherTeamId = Object.keys(item.game1).find(
                              (teamId) => teamId != team_id
                            );
                            if (item.game1) {
                              if (
                                item.game1[team_id] > item.game1[otherTeamId]
                              ) {
                                wins = wins + 1;
                              } else {
                                losses = losses + 1;
                              }
                            }

                            if (item.game2) {
                              if (
                                item.game2[team_id] > item.game2[otherTeamId]
                              ) {
                                wins = wins + 1;
                              } else {
                                losses = losses + 1;
                              }
                            }

                            if (item.game3) {
                              if (
                                item.game3[team_id] > item.game3[otherTeamId]
                              ) {
                                wins = wins + 1;
                              } else {
                                losses = losses + 1;
                              }
                            }

                            return wins + "W - " + losses + "L";
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-center">
                    <div>
                      <div className="flex justify-center">
                        <Avatar
                          alt={team2?.player1.first_name}
                          src={team2?.player1.avatar || "/generic.jpeg"}
                          sx={{
                            width: { lg: "100px", xs: "60px" },
                            height: { lg: "100px", xs: "60px" },
                          }}
                        />
                      </div>

                      <div className=" lg:text-[24px] text-[16px]  text-center my-3 text-[#244F62D9] font-[5Ï00]">
                        {team2?.player1.first_name +
                          " " +
                          team2?.player1.last_name}
                      </div>
                    </div>

                    <Divider
                      sx={{
                        borderBottom: "2px solid #12AFC2",
                        width: "30px",
                        position: "relative",
                        bottom: 24,
                        mx: 4,
                      }}
                    />
                    <div>
                      <div className="flex justify-center">
                        <Avatar
                          alt={team2?.player2.first_name}
                          src={team2?.player2.avatar || "/generic.jpeg"}
                          sx={{
                            width: { lg: "100px", xs: "60px" },
                            height: { lg: "100px", xs: "60px" },
                          }}
                        />
                      </div>

                      <div className=" lg:text-[24px] text-[16px]  text-center my-3 text-[#244F62D9] font-[500]">
                        {team2?.player2.first_name +
                          " " +
                          team2?.player2.last_name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="mt-4">
          <Loader />
        </div>
      )}
    </>
  );
};

export default MatchDetails;
