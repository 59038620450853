import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactHelmet from "react-helmet";
import { useParams } from "react-router-dom";
import ScoreConfirmed from "../components/ScoreConfirmed";
import ScoreRejectedModal from "../components/ScoreRejectedModal";

const ConfirmExtraMatch = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [match, setMatch] = useState(null);
  const { match_id } = useParams();
  const [loader, setLoader] = useState(true);
  const [errorMsg, setErrorMsg] = useState();
  const [team, setTeam] = useState();
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openAcceptedModal, setOpenAcceptedModal] = useState(false);
  const [errorState, setErrorState] = useState(true)

  const getTeam = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-team/" + match?.home_team)
      .then((res) => {
        console.log(res.data);
        setTeam(res.data.team);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
   let players = [match?.team1.players[0],match?.team1.players[1],match?.team2.players[0],match?.team2.players[1]]
   const userIdString = user._id.toString();
   const userIsPlayer = players.find(player => player == user._id);

   if (userIsPlayer) {
       console.log(`User ${user._id} is a player in this match.`);
       setErrorState(false)
   } else {
      setErrorState(true);
   }
  }, [match])
  

  const getMatch = async () => {
    console.log("hi");
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-match/" + match_id)
      .then((res) => {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        if (res.data.success) {
          console.log(res.data.match);
          setMatch(res.data.match);
        } else {
          setErrorMsg(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const rejectMatchScore = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/reject-match-score/", {
        match_id,
        accepted: false,
        user_id: user._id
      })
      .then((res) => {
        console.log(res.data)
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        if (res.data.success) {
          console.log(res.data.match);
          setMatch(res.data.match);
          setOpenRejectModal(true);
        } else {
          setErrorMsg(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const acceptMatchScore = async () => {
    
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/accept-match-score/", {
        match_id,
        accepted: true,
        user_id: user._id
      })
      .then((res) => {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        if (res.data.success) {
          console.log(res.data.match);
          setMatch(res.data.match);
          setOpenAcceptedModal(true);
        } else {
          setErrorMsg(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMatch();
  }, []);

  useEffect(() => {
    getTeam();
  }, [match]);
  const avatarStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };
  return (
    <div className="">
      <div className="bg-white rounded-[30px]   overflow-auto lg:w-[700px] px-4 my-8  pb-5">
        <ReactHelmet>
          <title>Confirm Extra Match Details - Paddle Party</title>
        </ReactHelmet>
        <br />

        <ScoreConfirmed
          open={openAcceptedModal}
          setOpen={setOpenAcceptedModal}
        />

        <ScoreRejectedModal
          open={openRejectModal}
          setOpen={setOpenRejectModal}
          match_id={match?._id}
        />

        <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
          Score Confirmation
        </div>

        <div className="flex justify-center mb-5">
          <div className="flex space-x-[-20px] lg:space-x-[-10px]">
            <div style={avatarStyle}>
              <img
                onError={(e) => {
                  e.target.src = "/generic.jpeg";
                }}
                src={team?.player1.avatar}
                alt="Avatar"
                style={imageStyle}
              />
            </div>
            <div style={avatarStyle}>
              <img
                onError={(e) => {
                  e.target.src = "/generic.jpeg";
                }}
                src={team?.player2.avatar}
                alt="Avatar"
                style={imageStyle}
              />
            </div>
          </div>
        </div>

        <div className="font-[700] text-[#45697A]  text-[17px] text-center mb-5">
          {team?.team_name}
        </div>

       {errorState ? (
          <div>
          {" "}
          <div className="text-center">This page can only be accessed by {match?.teams.find((e)=> e.team_id !== match?.home_team).team_name}</div>
          <Button
            sx={{
              bgcolor: "#44A093",
              color: "white",
              width: "100%",
              textTransform: "none",
              borderRadius: "5px",
              fontSize: "15px",
              ":hover": {
                bgcolor: "#44A093",
              },
            }}
            onClick={() => {
              window.location.pathname =
                "/dashboard";
            }}
          >
            Return Home
          </Button>
        </div>
       ) : (<> 
        <div className="text-[20px] text-[#45697A] px-5">
        {match?.match_location}
      </div>
      <div className="text-[20px] text-[#45697A] mb-7 px-5">
        {match?.match_date}
      </div>

      <div className="mb-7 text-[18px] px-5">
        {match &&
          match.score &&
          match.score.map((item, index) => {
            let otherTeamId = Object.keys(item.game1).find(
              (teamId) => teamId != team?._id
            );
            let homeTeamId = team?._id;
            return (
              <>
                <div className="text-[#45697A]">
                  <span className="font-bold text-[18px]">Game 1</span>{" "}
                  {item.game1[otherTeamId]}-{item.game1[homeTeamId]}(
                  {item.game1[otherTeamId] > item.game1[homeTeamId] &&
                    match.teams.find((e) => e.team_id == otherTeamId)
                      .team_name}
                  {item.game1[homeTeamId] > item.game1[otherTeamId] &&
                    match.teams.find((e) => e.team_id == homeTeamId)
                      .team_name}
                  )
                </div>

                <div className="text-[#45697A]">
                  <span className="font-bold text-[18px]">Game 2</span>{" "}
                  {item.game2[otherTeamId]}-{item.game2[homeTeamId]} (
                  {item.game2[otherTeamId] > item.game2[homeTeamId] &&
                    match.teams.find((e) => e.team_id == otherTeamId)
                      .team_name}
                  {item.game2[homeTeamId] > item.game2[otherTeamId] &&
                    match.teams.find((e) => e.team_id == homeTeamId)
                      .team_name}
                  )
                </div>

                {item.game3 && (
                  <div className="text-[#45697A]">
                    <span className="font-bold text-[18px]">Game 3</span>{" "}
                    {item.game3[otherTeamId]}-{item.game3[homeTeamId]} (
                    {item.game3[otherTeamId] > item.game3[homeTeamId] &&
                      match.teams.find((e) => e.team_id == otherTeamId)
                        .team_name}
                    {item.game3[homeTeamId] > item.game3[otherTeamId] &&
                      match.teams.find((e) => e.team_id == homeTeamId)
                        .team_name}
                    )
                  </div>
                )}
              </>
            );
          })}
      </div>
      {match?.score_accepted ? (
        <div className="text-center"> You have accepted scores </div>
      ) : (
        <>
          {match?.score  ? (
             <> 
             {match.team_that_submitted_score == user.team_id ? (<div className="text-center">
              You or your team mate submited score, allow the other team accept the score.
             </div> ): (
                <div className=" space-x-3 lg:px-0 flex items-center">
                <Button
                  sx={{
                    bgcolor: "#F58080",
                    color: "white",
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "5px",
                    fontSize: { lg: "22px", xs: "13px" },
                    fontWeight: "bold",
                    ":hover": {
                      bgcolor: "#F58080",
                    },
                  }}
                  onClick={rejectMatchScore}
                >
                  Reject Scores
                </Button>
                <Button
                  sx={{
                    bgcolor: "#44A093",
                    color: "white",
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "5px",
                    fontSize: { lg: "22px", xs: "13px" },
                    fontWeight: "bold",
                    ":hover": {
                      bgcolor: "#44A093",
                    },
                  }}
                  onClick={acceptMatchScore}
                >
                  Accept Scores
                </Button>
              </div>
             )}
             </>
          ) : (
            <div>
              {" "}
              <div className="text-center">No scores yet</div>
              <Button
                sx={{
                  bgcolor: "#44A093",
                  color: "white",
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "15px",
                  ":hover": {
                    bgcolor: "#44A093",
                  },
                }}
                onClick={() => {
                  window.location.pathname =
                    "/dashboard/submit-extra-match/" + match_id;
                }}
              >
                Submit Scores
              </Button>
            </div>
          )}
        </>
      )}
       </>)}



      </div>
    </div>
  );
};

export default ConfirmExtraMatch;
