import { Avatar, AvatarGroup, Button, Divider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import ReactHelmet from "react-helmet";
import { Add, ArrowRight, KeyboardArrowRight } from "@mui/icons-material";

const OpenProposals = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [matches, setMatches] = useState();
  const [match_proposals, setMatchProposals] = useState([]);
  const navigate = useNavigate();
  const getProposals = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/get-open-proposals/",{team_id:user.team_id, league_id : user.league})
      .then((res) => {
        console.log(res.data);
        setMatchProposals(res.data.match_proposals);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      getProposals();
    }
  }, []);
  const avatarStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  function getDayOfWeek(dateString) {
    const date = new Date(dateString);
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayOfWeek = date.getUTCDay(); // getUTCDay returns the day of the week (0 for Sunday, 1 for Monday, etc.)
    return daysOfWeek[dayOfWeek];
  }

  function getFormattedDate(dateString) {
    const date = new Date(dateString);
    const month = date.getUTCMonth() + 1; // getUTCMonth() returns month from 0-11, so we add 1
    const day = date.getUTCDate();
    return `${month}/${day}`;
  }


  const isPastDate = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    // Ignore the time part by setting hours to 0
    //inputDate.setHours(0, 0, 0, 0);
    //currentDate.setHours(0, 0, 0, 0);
    return inputDate < currentDate;
  };
  
  
  return (
    <div className="">
      <div className="bg-white rounded-[30px] space-y-4   overflow-auto lg:w-[700px] px-8 lg:px-48 lg:py-5 py-3 my-8 font-[400] text-[16px] lg:text-[19px] ">
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={()=> navigate('/dashboard/create-new-match-proposal')}
        >
          <img src="/plusyellow.png" className="w-[21px]" />{" "}
          <div className="underline-cross" >Create A Proposal</div>
        </div>
        <div className="flex items-center space-x-2 cursor-pointer" onClick={()=> navigate('/dashboard/manage-match-proposals')}>
          <img src="/note.png" className="w-[21px]" />{" "}
          <div className="underline-cross"> Manage My Proposals </div>
        </div>
      </div>

      <div className="bg-white rounded-[30px]   overflow-auto lg:w-[700px] px-4 my-8  pb-5">
        <ReactHelmet>
          <title>Open proposal - Paddle Party</title>
        </ReactHelmet>

        <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
          Open Proposals
        </div>
        {match_proposals &&
          match_proposals.map((proposal, index) => {
            const getTeam = async () => {
                await axios
                  .get(process.env.REACT_APP_BACKEND_URL + "/get-team/"+proposal.team_id)
                  .then((res) => {
                    console.log(res.data);
                    let team = res.data.team
                   document.getElementById('team_name_'+index).innerHTML = team.team_name
                   document.getElementById('player1_img_'+index).src = team.player1.avatar
                   document.getElementById('player2_img_'+index).src = team.player2.avatar


                    
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              };

              getTeam()


            return (
              <div className="w-full text-[#244F62] cursor-pointer">
                <div
                  className="flex items-start"
                  onClick={() => {
                    navigate("/dashboard/proposal-details/"+proposal._id);
                  }}
                >
                  <div className="flex space-x-[-20px] lg:space-x-[-10px]">
                    <div style={avatarStyle}> 
                      <img
                        onError={(e) => {
                          e.target.src = "/generic.jpeg";
                        }}
                        src={""}
                        alt="Avatar"
                        style={imageStyle}
                        id={'player1_img_'+index}
                      />
                    </div>
                    <div style={avatarStyle}>
                      <img
                        onError={(e) => {
                          e.target.src = "/generic.jpeg";
                        }}
                        src={""}
                        alt="Avatar"
                        style={imageStyle}
                        id={'player2_img_'+index}
                      />
                    </div>
                  </div>

                  <div className="flex-1">
                    <div className="font-[900] text-[20px]" id={'team_name_'+index}></div>
                    <div className="">
                      {proposal.court_name}
                    </div>

                    <div className="flex items-center space-x-4 mt-2 w-[180px] lg:w-full  overflow-x-auto">
                      {proposal.timeslots.sort((a, b) => new Date(a.date) - new Date(b.date)).map((slot, index) => 
                     
                      
                        {
                          const inputDate = (new Date(slot.date+ 'T' + slot.from)).getTime();
                          const currentDate = (new Date()).getTime();
                          console.log(inputDate - currentDate)
                          // Ignore the time part by setting hours to 0
                          // inputDate.setHours(0, 0, 0, 0);
                          //currentDate.setHours(0, 0, 0, 0);
                          if(inputDate > currentDate){
                            return(
                              <div className="bg-[#EEF1F5] rounded-full px-4 py-2 ">
                                <div className="font-bold">
                                  {getDayOfWeek(slot.date)}
                                </div>
                                <div>{getFormattedDate(slot.date)}</div>
                              </div>
                            )
                          }
                           
                          
                          
                          
                        })}
                    </div>
                  </div>

                  {proposal.is_open && (
                   <div>
                   <KeyboardArrowRight
                     sx={{ color: "#FEC901", fontSize: "60px", marginTop:'40px' }}
                     onClick={() => {
                      navigate("/dashboard/proposal-details/"+proposal._id);
                    }}
                   />
                 </div>
                 )}
                </div>
              </div>
            );
          })}

        {!match_proposals || match_proposals.length < 1 && (
          <div className=" text-center text-[14px] lg:text-[19px]">
            <div className="font-bold"> No Proposals</div>
            There aren’t any proposals at the moment. Create a new one using the
            links above.
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenProposals;
