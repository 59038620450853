import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress, Box } from '@mui/material';

function CourtNameField({ locations, proposal, setProposal, setSelectedValue }) {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [inputValue]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
    setLoading(true);

    const location = locations.find((loc) => loc.name === value);
    if (location) {
      setProposal({
        ...proposal,
        court_name_id: location._id,
        court_address: location.address,
        court_name: location.name,
        is_paid: location.is_paid,
      });
      setSelectedValue(location.is_paid ? 'yes' : 'no');
    } else {
      setProposal({
        ...proposal,
        court_name_id: null,
        court_address: '',
        court_name: value,
        is_paid: false,
      });
      setSelectedValue('no');
    }
  };

  return (
    <Box position="relative">
      <Autocomplete
        freeSolo
        options={loading ? [] : locations.map((loc) => loc.name)}
        value={proposal.court_name}
        onInputChange={handleInputChange}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            placeholder="Enter a court name"
            onClick={() => setLoading(true)}
            sx={{
              border: '1px solid #44A093',
              bgcolor: 'rgba(68, 160, 147, 0.02)',
              borderRadius: '5px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiAutocomplete-inputRoot': {
                padding: '1px 2px',
              },
            }}
          />
        )}
      />
      {/* {loading && (
        <Box
          position="absolute"
          top={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <CircularProgress 
            size={24}
            sx={{ color: '#44A093' }} 
          />
        </Box>
      )} */}
    </Box>
  );
}

export default CourtNameField;
