import {
  Avatar,
  AvatarGroup,
  Button,
  Divider,
  FormControlLabel,
  InputBase,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import ReactHelmet from "react-helmet";
import { Add, Cancel } from "@mui/icons-material";
import ConfirmProposalModal from "../components/ConfirmProposalModal";
import Snack from "../components/Snack";
import CourtNameField from "../components/CourtNameField";
import DateInput from "../components/DateInput";
import SnackRed from "../components/SnackRed";
import CourtAddressFeild from "../components/CourtAddressFeild";

const CreateNewMatchProposal = () => {
  const [openConfirmProposalModal, setOpenConfirmProposalModal] =
    useState(false);
    const getCurrentTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    };
  
    const minTime = getCurrentTime();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [locations, setLocations] = useState([]);
  const [proposal, setProposal] = useState({
    league_id: user.league,
    user_id: user._id,
    team_id: user.team_id,
    court_name: "",
    court_name_id: "",
    court_address: "",
    is_paid: false,
    payment_details: "",
    timeslots: [{ date: "", from: minTime, to: minTime, id: 1 }],
  });
  const [matches, setMatches] = useState();
  const navigate = useNavigate();
  const getMatches = async () => {
    console.log(user.team_id);
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-matches/" + user.team_id)
      .then((res) => {
        setMatches(res.data.matches);
        console.log(res.data);
        console.log(res.data.matches);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      getMatches();
    }
  }, []);
  const avatarStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  //for radio button

  const [selectedValue, setSelectedValue] = useState("no");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "yes") {
      setProposal({ ...proposal, is_paid: true });
    } else {
      setProposal({ ...proposal, is_paid: false, payment_details: "" });
    }
  };

  //setting max date for date input
  const [maxdate, setMaxdate] = useState();
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Format month and day to ensure they are two digits
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    setMaxdate(`${year}-${month}-${day}`);
  }, []);

  //TIMESLOT
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const newTimeSlots = proposal.timeslots.map((slot, i) =>
      i === index ? { ...slot, [name]: value } : slot
    );
    console.log(newTimeSlots);
    setProposal({ ...proposal, timeslots: newTimeSlots });
  };

  const [errorMsg, setErrormsg] = useState("");

  // Convert time strings to minutes since midnight
  const getMinutesFromMidnight = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const addTimeSlot = () => {
    const newSlot = {
      date: "",
      from: minTime,
      to: minTime,
      id: proposal.timeslots.length + 1,
    };
    console.log(newSlot);
    console.log(proposal.timeslots[proposal.timeslots.length - 1].date);
    console.log(user.league_data.league_end_date);
    if (
      proposal.timeslots[proposal.timeslots.length - 1].date.length > 1 &&
      proposal.timeslots[proposal.timeslots.length - 1].from.length > 1 &&
      proposal.timeslots[proposal.timeslots.length - 1].to.length > 1
    ) {
      const minutes1 = getMinutesFromMidnight(
        proposal.timeslots[proposal.timeslots.length - 1].to
      );
      const minutes2 = getMinutesFromMidnight(
        proposal.timeslots[proposal.timeslots.length - 1].from
      );
      const differenceInMinutes = minutes1 - minutes2;
      console.log(differenceInMinutes);
      if (differenceInMinutes >= 15) {
        // Convert the date strings into UTC Date objects
        const dateObj1 = new Date(
          Date.UTC(
            parseInt(
              proposal.timeslots[proposal.timeslots.length - 1].date.split(
                "-"
              )[0]
            ),
            parseInt(
              proposal.timeslots[proposal.timeslots.length - 1].date.split(
                "-"
              )[1]
            ) - 1, // Month is 0-based
            parseInt(
              proposal.timeslots[proposal.timeslots.length - 1].date.split(
                "-"
              )[2]
            )
          )
        );
        const dateObj2 = new Date(user.league_data.league_end_date);
        console.log(dateObj1);
        console.log(dateObj2);
        if (dateObj1 > dateObj2) {
          console.log("hi");
          setErrormsg(
            `please chose an end date that is before the league end date (${String(
              dateObj2
            ).slice(0, 16)})`
          );
          return;
        } else {
          setErrormsg("");
          setProposal({
            ...proposal,
            timeslots: [...proposal.timeslots, newSlot],
          });
        }
      } else {
        setErrormsg(
          "please chose an end time that is atleast 15 minutes after the start time"
        );
      }
    }
  };

  const removeTimeSlot = (id) => {
    const newTimeSlots = proposal.timeslots.filter((slot) => slot.id !== id);
    setProposal({ ...proposal, timeslots: newTimeSlots });
  };

  //GET LOCATIONS
  const getLocations = async () => {
    await axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          "/get-locations/" +
          user.league_data.city
      )
      .then((res) => {
        console.log(res.data);
        setLocations(res.data.locations);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getLocations();
  }, []);

  //Submit Proposal

  const submitProposal = async () => {
    console.log(proposal);
    if (
      proposal.court_name.length < 1 ||
      proposal.court_name == "select a court name"
    ) {
      setOpenSnackRed(true);
      setSnackRedMsg("Please select a court name");
      return;
    } else {
      if (proposal.court_address.length < 3) {
        setOpenSnackRed(true);

        setSnackRedMsg("Please input a valid court_address");
        return;
      } else {
        let issue = 0
        proposal.timeslots.map((slot, id) => {
          if (
            slot.date.length > 1 &&
            slot.from.length > 1 &&
            slot.to.length > 1
          ) {
            const minutes1 = getMinutesFromMidnight(slot.to);
            const minutes2 = getMinutesFromMidnight(slot.from);
            const differenceInMinutes = minutes1 - minutes2;
            console.log(differenceInMinutes);
            if (differenceInMinutes >= 15) {
              // Convert the date strings into UTC Date objects
              const dateObj1 = new Date(
                Date.UTC(
                  parseInt(slot.date.split("-")[0]),
                  parseInt(slot.date.split("-")[1]) - 1, // Month is 0-based
                  parseInt(slot.date.split("-")[2])
                )
              );
              const dateObj2 = new Date(user.league_data.league_end_date);
              console.log(dateObj1);
              console.log(dateObj2);
              if (dateObj1 > dateObj2) {
                console.log("hi");
                issue = issue + 1
                setOpenSnackRed(true);

                setSnackRedMsg(
                  `please chose an end date that is before the league end date (${String(
                    dateObj2
                  ).slice(0, 16)})  ---timeslot_${slot.id}`
                );
                return;
              } else {
                issue = 0
                setErrormsg("");
                
              }
            } else {
              issue = issue+1
              setOpenSnackRed(true);

              setSnackRedMsg(
                ` please chose an end time that is atleast 15 minutes after the start time ---timeslot_${slot.id}`
              );
              return;
            }
          }
        });

        if(issue == 0){
          setOpenConfirmProposalModal(true);
        }
      }
    }

    
  };

  const [openSnack, setOpenSnack] = useState();
  const [snackMsg, setSnackMsg] = useState();
  const [openSnackRed, setOpenSnackRed] = useState(false);
  const [snackredmsg, setSnackRedMsg] = useState("");



  return (
    <div className="bg-white rounded-[30px]   overflow-auto lg:w-[700px] lg:px-8 px-2 my-8 pb-10 ">
      <Snack open={openSnack} setOpen={setOpenSnack} msg={snackMsg} />
      <SnackRed
        open={openSnackRed}
        setOpen={setOpenSnackRed}
        msg={snackredmsg}
      />
      <ReactHelmet>
        <title>Create New Proposal - Paddle Party</title>
      </ReactHelmet>

      <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
        New Match Proposal
      </div>

      <div className="font-[700] text-[18px] ">Court Details</div>

      <div className="font-[400] text-[16px] text-[#244F62D9] ">
        Where would you like to play? Please include the name and address. If
        you aren’t sure where to play, check out your{" "}
        <span className="text-[#06B3D2] underline-cross">league resources</span>
        .{" "}
      </div>

      <div className="lg:px-20 px-4 space-y-5   lg:overflow-auto text-[#244F62D9]">
        <div>
          <div className=" lg:text-[18px] text-[14px]  mt-3 text-[#244F62D9] font-[400]">
            Court Name
          </div>
          <div className=" lg:text-[15px] text-[13px]  my-1 text-[#244F62D9] font-[400]">
          Select a popular court or enter your own.
          </div>
          <div className="">
            <CourtNameField
              locations={locations}
              proposal={proposal}
              setProposal={setProposal}
              setSelectedValue={setSelectedValue}
            />
          </div>
        </div>

        <div>
          <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
            Court Address
          </div>
        
          
          <div className="">
           <CourtAddressFeild proposal={proposal} setProposal={setProposal}/>
          </div>
        </div>

        <div>
          <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
            Do guests have to pay at this court?
          </div>

          <RadioGroup
            aria-label="options"
            name="options"
            value={selectedValue}
            onChange={handleChange}
          >
            <div className="flex items-center">
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    sx={{
                      color: "#06B3D2",
                      "&.Mui-checked": { color: "#06B3D2" },
                    }}
                  />
                }
                label="Yes, other players may need to pay"
              />
            </div>
            <div className="flex items-center">
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    sx={{
                      color: "#06B3D2",
                      "&.Mui-checked": { color: "#06B3D2" },
                    }}
                  />
                }
                label="No, the other players won’t need to pay"
              />
            </div>
          </RadioGroup>
        </div>

        {selectedValue == "yes" && (
          <div>
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              What are the payment details?
            </div>
            <div className="">
              <InputBase
                id=""
                rows={5}
                multiline
                placeholder="Eg: Members are free, but guest pass is $10/player. "
                sx={{
                  width: "100%",
                  border: "1px solid #44A093",
                  bgcolor: "rgba(68,160,147,0.02)",
                  borderRadius: "5px",
                  pl: 2,
                  py: "4px",
                }}
                value={proposal.payment_details}
                onChange={(e) => {
                  setProposal({ ...proposal, payment_details: e.target.value });
                }}
              />
            </div>
          </div>
        )}

        <Divider />

        <div>
          <div className="font-[700] text-[18px] ">Timeslots</div>

          <div className="font-[400] text-[16px] text-[#244F62D9] ">
            Enter up to 7 timeslots that you and your partner are available and
            would like to play. The time window should be from your{" "}
            <span className="font-bold">
              {" "}
              earliest start time to your latest start time.
            </span>
          </div>
        </div>

        {proposal.timeslots &&
          proposal.timeslots.map((slot, index) => (
            <div className="flex items-center space-x-4   ">
              <div className="lg:w-full w-[80px] ">
                <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                  Date
                </div>
                <div className="w-full">
                  <DateInput
                    slot={slot}
                    handleInputChange={handleInputChange}
                    index={index}
                    user={user}
                  />
                </div>
              </div>
              <div className="lg:w-full w-[80px]">
                <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                  From
                </div>
                <div className="w-full">
                  <InputBase
                    id="datef"
                    name="from"
                    type="time"
                    sx={{
                      width: "100%",
                      border: "1px solid #44A093",
                      bgcolor: "rgba(68,160,147,0.02)",
                      borderRadius: "5px",
                      pl: 2,
                      py: "1px",
                      "&:focus": {
                        borderColor: "#44A093", // Set focus border color
                        outline: "none", // Remove default focus outline
                      },
                    }}
                    inputProps={{
                      min: minTime, // Set the minimum time to the current time
                      max: maxdate, // Set the maximum time
                    }}
                    className="date-input"
                    value={slot.from}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </div>
              </div>

              <div className="lg:w-full w-[80px]">
                <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                  To
                </div>
                <div className="w-full">
                  <InputBase
                    id="datef"
                    type={"time"}
                    name="to"
                    sx={{
                      width: "100%",
                      border: "1px solid #44A093",
                      bgcolor: "rgba(68,160,147,0.02)",
                      borderRadius: "5px",
                      pl: 2,
                      py: "1px",
                      "&:focus": {
                        borderColor: "#44A093", // Set focus border color
                        outline: "none", // Remove default focus outline
                      },
                    }}
                    inputProps={{
                      min: minTime, // Set the minimum time to the current time
                      max: maxdate, // Set the maximum time
                    }}
                    className="date-input"
                    value={slot.to}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </div>
              </div>

              {slot.id !== 1 && (
                <div
                  className="mt-12 cursor-pointer"
                  onClick={() => removeTimeSlot(slot.id)}
                >
                  <Cancel sx={{ color: "gray", fontSize: "15px" }} />
                </div>
              )}
            </div>
          ))}

        {errorMsg && (
          <div className="text-red-600 text-[15px]"> {errorMsg} </div>
        )}

        {/* <div className="flex items-center space-x-4 ">
          <div className="lg:w-full w-[80px] ">
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              Date
            </div>
            <div className="w-full">
              <InputBase
                id="datef"
                type={"date"}
                value={proposal.timeslots.filter((e) => {
                  if (e.id == 1) {
                    return e.date;
                  }
                })}
                onChange={(e) => {
                  setProposal(
                    ...proposal,
                    proposal.timeslots.find((e) => {
                      if (e.id == 1) {
                      }
                    })
                  );
                }}
                sx={{
                  width: "100%",
                  border: "1px solid #44A093",
                  bgcolor: "rgba(68,160,147,0.02)",
                  borderRadius: "5px",
                  pl: 2,
                  py: "1px",
                  "&:focus": {
                    borderColor: "#44A093", // Set focus border color
                    outline: "none", // Remove default focus outline
                  },
                }}
                inputProps={{
                  max: maxdate, // Set the minimum date to today
                }}
                className="date-input"
              />
            </div>
          </div>
          <div className="lg:w-full w-[80px]">
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              From
            </div>
            <div className="w-full">
              <InputBase
                id="datef"
                type={"time"}
                sx={{
                  width: "100%",
                  border: "1px solid #44A093",
                  bgcolor: "rgba(68,160,147,0.02)",
                  borderRadius: "5px",
                  pl: 2,
                  py: "1px",
                  "&:focus": {
                    borderColor: "#44A093", // Set focus border color
                    outline: "none", // Remove default focus outline
                  },
                }}
                inputProps={{
                  max: maxdate, // Set the minimum date to today
                }}
                className="date-input"
              />
            </div>
          </div>

          <div className="lg:w-full w-[80px]">
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              To
            </div>
            <div className="w-full">
              <InputBase
                id="datef"
                type={"time"}
                sx={{
                  width: "100%",
                  border: "1px solid #44A093",
                  bgcolor: "rgba(68,160,147,0.02)",
                  borderRadius: "5px",
                  pl: 2,
                  py: "1px",
                  "&:focus": {
                    borderColor: "#44A093", // Set focus border color
                    outline: "none", // Remove default focus outline
                  },
                }}
                inputProps={{
                  max: maxdate, // Set the minimum date to today
                }}
                className="date-input"
              />
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4   ">
          <div className="lg:w-full w-[80px] ">
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              Date
            </div>
            <div className="w-full">
              <InputBase
                id="datef"
                type={"date"}
                sx={{
                  width: "100%",
                  border: "1px solid #44A093",
                  bgcolor: "rgba(68,160,147,0.02)",
                  borderRadius: "5px",
                  pl: 2,
                  py: "1px",
                  "&:focus": {
                    borderColor: "#44A093", // Set focus border color
                    outline: "none", // Remove default focus outline
                  },
                }}
                inputProps={{
                  max: maxdate, // Set the minimum date to today
                }}
                className="date-input"
              />
            </div>
          </div>
          <div className="lg:w-full w-[80px]">
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              From
            </div>
            <div className="w-full">
              <InputBase
                id="datef"
                type={"time"}
                sx={{
                  width: "100%",
                  border: "1px solid #44A093",
                  bgcolor: "rgba(68,160,147,0.02)",
                  borderRadius: "5px",
                  pl: 2,
                  py: "1px",
                  "&:focus": {
                    borderColor: "#44A093", // Set focus border color
                    outline: "none", // Remove default focus outline
                  },
                }}
                inputProps={{
                  max: maxdate, // Set the minimum date to today
                }}
                className="date-input"
              />
            </div>
          </div>

          <div className="lg:w-full w-[80px]">
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              To
            </div>
            <div className="w-full">
              <InputBase
                id="datef"
                type={"time"}
                sx={{
                  width: "100%",
                  border: "1px solid #44A093",
                  bgcolor: "rgba(68,160,147,0.02)",
                  borderRadius: "5px",
                  pl: 2,
                  py: "1px",
                  "&:focus": {
                    borderColor: "#44A093", // Set focus border color
                    outline: "none", // Remove default focus outline
                  },
                }}
                inputProps={{
                  max: maxdate, // Set the minimum date to today
                }}
                className="date-input"
              />
            </div>
          </div>

          <div className="mt-12 cursor-pointer">
            <Cancel sx={{ color: "gray", fontSize: "15px" }} />
          </div>
        </div> */}
        {proposal && proposal?.timeslots.length < 3 && (
          <div className="font-[400] text-[16px] text-[#244F62D9] text-center ">
            Matches that have 3 or more timeslots are more likely to to get
            accepted.
          </div>
        )}
        <div className="px-10 lg:px-0">
          {proposal && proposal?.timeslots.length < 7 ? (
            <Button
              sx={{
                bgcolor: "#E4E4E4",
                color: "white",
                width: "100%",
                textTransform: "none",
                borderRadius: "5px",
                fontSize: { lg: "22px", xs: "13px" },
                fontWeight: "bold",
                ":hover": {
                  bgcolor: "#E4E4E4",
                },
              }}
              onClick={addTimeSlot}
              startIcon={<img src="/plusblack.png" className="w-[20px]" />}
            >
              Add Timeslot
            </Button>
          ) : (
            <div className="text-center">
              {" "}
              You can have only up to 7 timeslots{" "}
            </div>
          )}
        </div>

        <div className="px-10 lg:px-0">
          <ConfirmProposalModal
            open={openConfirmProposalModal}
            setOpen={setOpenConfirmProposalModal}
            proposal={proposal}
            setProposal={setProposal}
            setOpenSnack={setOpenSnack}
            setSnackMsg={setSnackMsg}
            setSelectedValue={setSelectedValue}
            setOpenSnackRed={setOpenSnackRed}
            setSnackRedMsg={setSnackRedMsg}
          />
          <Button
            disabled={proposal.timeslots[0].date.length < 1 && true}
            sx={{
              bgcolor: "#44A093",
              color: "white",
              width: "100%",
              textTransform: "none",
              borderRadius: "5px",
              fontSize: { lg: "22px", xs: "17px" },
              fontWeight: "bold",
              ":hover": {
                bgcolor: "#44A093",
              },
            }}
            onClick={() => {
              submitProposal();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateNewMatchProposal;
