import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

function TokenFailed({open, setOpen}) {

  const handleOpen = () => setOpen(true);

  return (
      
      <Dialog open={open} onClose={false} disableBackdropClick>
      <div className='flex justify-center '>
        <div className='bg-white border-0 py-4 px-4 lg:w-[40vw] mx-4 lg:mx-0 '>
            <div className='text-center text-red-700 mb-2 text-[22px] font-[600] ' >Login With Token Failed</div>
            <div className='text-center text-[18px] text-[#244F62D9] '>
            Your token is broken or incorrect , input correct token and reload page or login via email, thanks.
            </div>

            <div className=" flex justify-center mt-[50px] ">
               
               <Button
                 sx={{
                   bgcolor: "#44A093",
                   color: "white",
                   width: "150px",
                   textTransform: "none",
                   borderRadius: "5px",
                   fontSize: "15px",
                   ":hover": {
                     bgcolor: "#44A093",
                   },
                 }}
                 onClick={()=> {
                    window.location.pathname ='/login'
                 }}
               >
                Login
               </Button>
           </div>
        </div>
        </div>
      </Dialog>
  );
}

export default TokenFailed;
