import { Cancel } from "@mui/icons-material";
import { Button, Checkbox, MenuItem, Modal, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AcceptProposalModal = ({ open, setOpen , proposal,setOpenSnack,setSnackMsg, home_team, away_team, setProposal,setOpenSnackRed,setSnackRedMsg}) => {
  const [checked, setChecked] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const navigate = useNavigate()
 
  


  const [slot, setSlot] = useState({
    id:0,
    time: ''
  })




  
 

  const acceptProposal = async() => {
    let acceptedProposal = {...proposal, is_accepted: true, is_open: false, opponent_id: user._id, opponent_team_id: user.team_id,
        opponents_timeslot_choice :  slot.interval.replace(/^\w+, /, ''), opponents_timeslot_choice_id :  slot.id,
    }

    let match = {
        week_number: 1,
        hidden: false,
        match_name : home_team.team_name+" vs. "+away_team.team_name,
        week_name: slot.interval.replace(/^\w+, /, '')  ,
        teams: [
            {team_name: home_team.team_name , team_id: home_team._id},
            {team_name: away_team.team_name , team_id: away_team._id},
        ],
        winning_team: null,
        home_team: home_team._id,
        is_forfeit: false,
        chat_url: null,
        match_date: slot.time,
        match_location: proposal.court_name,
        score: null,
        registered_with_partner: false,
        proposed_match : true,
        proposal_id : proposal._id,
        opponent_team_id: user.team_id,
        opponents_timeslot_choice :  slot.interval.replace(/^\w+, /, ''), opponents_timeslot_choice_id :  slot.id,
        
    }





    await axios.post(process.env.REACT_APP_BACKEND_URL+'/update-proposal', {proposal:acceptedProposal, match})
    
    .then((res)=> {
        if(res.data.success){
        setProposal(res.data.match_proposal)
        setTimeout(() => {
          navigate('/dashboard/match/'+res.data.match._id)
        }, 1000);
        }else{
          setOpenSnackRed(true)
          setSnackRedMsg(res.data.msg)
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
    })
    .catch((err)=> {
    })



    // await axios.post(process.env.REACT_APP_BACKEND_URL+'/create-match', match)
    // .then((res)=> {
    //     if(res.data.success){
    //       setTimeout(() => {
    //         navigate('/dashboard/match/'+res.data.match_._id)
    //       }, 1000);
    //       setOpen(false)
    //       setOpenSnack(true)
    //       setSnackMsg('Match Proposal Accepted')
    //     }else{
    //       setOpenSnackRed(true)
    //       setSnackRedMsg(res.data.msg)
    //     }
       
        
    // })
    // .catch((err)=> {
    // })
  }



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };


  const generateIntervals = (start, stop, slot_id) => {
    const startDate = new Date(start);
    const stopDate = new Date(stop);
    const intervals = [];
    let currentDate = new Date(startDate);
    console.log({currentDate: currentDate})
  
    // Function to round minutes to nearest 15, 30, 45, or 60
    const roundToNearestQuarterHour = (minutes) => {
      if (minutes <= 15) return 15;
      if (minutes <= 30) return 30;
      if (minutes <= 45) return 45;
      return 0; // If it's past 45 minutes, set to 0 and increment the hour
    };
  
   
    // Ensure the start time is rounded to the nearest quarter hour if not already
    let startMinutes = currentDate.getMinutes();
    if (![0, 15, 30, 45].includes(startMinutes)) {
      let roundedStartMinutes = roundToNearestQuarterHour(startMinutes);
      if (roundedStartMinutes === 0 && startMinutes !== 0) {
        currentDate.setHours(currentDate.getHours() + 1);
      }
      currentDate.setMinutes(roundedStartMinutes);
      currentDate.setSeconds(0); // Reset seconds to 0
    }
  
    while (currentDate <= stopDate) {
      let currentMinutes = currentDate.getMinutes();
      if ([0, 15, 30, 45].includes(currentMinutes)) {
        intervals.push({
          interval: formatDate(currentDate),
          slot_id: currentDate.getTime()
        });
      }
      // Increment by 15 minutes for the next iteration
      currentDate.setMinutes(currentDate.getMinutes() + 15);
    }
  
  
  
    return intervals;
  };
  
  



  
  
  // Function to filter and sort dates, then format them
  const filterSortAndFormatDates = (arr, dateField) => {
    const now = (new Date()).getTime();
    console.log({now})

    return arr
       .filter(item => (new Date(item[dateField] + 'T' + item.from)).getTime() > now) //Filter future dates
      .sort((a, b) => new Date(a[dateField]) - new Date(b[dateField]))  // Sort dates
      .reduce((acc, item) => {
        const intervals = generateIntervals(item[dateField] + 'T' + item.from, item[dateField] + 'T' + item.to,item.id)
        return acc.concat(intervals);  // Merge intervals into one array
      }, [])
  };


  
  const results = filterSortAndFormatDates(proposal.timeslots, 'date');


  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="flex justify-center lg:mt-20 mt-5 text-[#244F62D9] ">
        <div className="bg-white border-0 py-4 px-4 lg:w-[40vw] mx-4 lg:mx-0  rounded-[20px] relative">
          <Cancel
            sx={{
              color: "gray",
              position: "absolute",
              top: 4,
              right: 4,
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(false);
            }}
          />
          <div className="text-center text-[#06B3D2] text-[22px] font-[600] ">
            Accept Proposal
          </div>
          <div className="space-y-4">
            <div className=" text-[16px] lg:text-[18px] text-[#244F62D9] ">
              Confirm the details of the match below:
            </div>

            <div className=" text-[16px] lg:text-[18px] text-[#244F62D9] ">
              <span className="font-bold">Court: </span>{proposal.court_name}
            </div>

            <div className=" text-[16px] lg:text-[18px] text-[#244F62D9] flex items-center space-x-3 ">
              <div className="font-bold">Timeslots:</div>
              <div className=" w-full ">
                <TextField
                  value={slot.id}
                  select
                  variant="outlined"
                  fullWidth
                  sx={{
                    border: "1px solid #44A093",
                    bgcolor: "rgba(68, 160, 147, 0.02)",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiSelect-root": {
                      padding: "1px 2px",
                    },
                  }}

                  onChange={(e)=> {
                    results.find(f=> {
                        if(f.slot_id == e.target.value){
                            setSlot({...slot, id: e.target.value, interval: f.interval })
                        }
                    })
                    
                  }}
                >
                  <MenuItem  value={"0"} >Select a time</MenuItem>
                  {proposal.timeslots && results.map((slot,index)=> (
                    <MenuItem  value={slot.slot_id} >{slot.interval}</MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            <div className="mt-4">
            Have you confirmed this time and place with your partner? 
            </div>
          </div>

          <div className="flex items-center mt-3">
            {" "}
            <div>
              <Checkbox
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
            </div>
            <div>Yes I’ve confirmed these times work with my partner.  </div>
          </div>

          {checked && home_team && away_team && slot?.interval?.length > 1 ? (
            <div className=" flex justify-center mt-[50px] ">
              <Button
                sx={{
                  bgcolor: "#44A093",
                  color: "white",
                  width: "150px",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "15px",
                  ":hover": {
                    bgcolor: "#44A093",
                  },
                }}
                onClick={acceptProposal}
              >
                Confirm
              </Button>
            </div>
          ) : (
            <div className=" flex justify-center mt-[50px] ">
              <Button
                sx={{
                  bgcolor: "#44A093",
                  color: "gray",
                  width: "150px",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "15px",
                  ":hover": {
                    bgcolor: "gray",
                  },
                }}
               
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AcceptProposalModal;
