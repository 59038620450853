import { Avatar, Button, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SideBar = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  

  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <div className="lg:col-span-1 h-full  bg-white px-2   space-y-4  shadow-md   flex flex-col pb-4 ">
       {user.league_data.on_demand && (
        <>
         {window.location.pathname === "/dashboard/home" ? (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/home");
            }}
            className={
              "text-[20px] text-[#12AFC2] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
            }
          >
            <img src="/h1.png" className="w-[34px] h-[34px]" />{" "}
            <div>Home</div>
          </div>
        ) : (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/home");
            }}
            className={
              "text-[20px] text-[#B1B1B1] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
            }
          >
            <img src="/h2.png" className="w-[34px] h-[34px]" />{" "}
            <div>Home</div>
          </div>
        )}</>
       )}
       


       {!user.league_data.on_demand && (
        <>
         {window.location.pathname === "/dashboard/my-matches" ? (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/my-matches");
            }}
            className={
              "text-[20px] text-[#12AFC2] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
            }
          >
            <img src="/home.png" className="w-[34px] h-[34px]" />{" "}
            <div>My matches</div>
          </div>
        ) : (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/my-matches");
            }}
            className={
              "text-[20px] text-[#B1B1B1] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
            }
          >
            <img src="/home.png" className="w-[34px] h-[34px]" />{" "}
            <div>My matches</div>
          </div>
        )}</>
       )}

     {user.league_data.on_demand && (
      <>
         {window.location.pathname === "/dashboard/open-proposals" ? (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/open-proposals");
            }}
            className={
              "text-[20px] text-[#12AFC2] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
            }
          >
            <img src="/ppb.png" className="w-[34px] h-[34px]" />{" "}
            <div>Proposals</div>
          </div>
        ) : (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/open-proposals");
            }}
            className={
              "text-[20px] text-[#B1B1B1] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
            }
          >
            <img src="/icon1a.png" className="w-[34px] h-[34px]" />{" "}
            <div>Proposals</div>
          </div>
        )}
      </>
     )}

        {window.location.pathname === "/dashboard/submit-scores" ? (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/submit-scores");
            }}
            className={
              "text-[20px] text-[#12AFC2] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
            }
          >
            <img src="/icon2b.png" className="w-[19px] h-[24px]" />{" "}
            <div>Submit Scores</div>
          </div>
        ) : (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/submit-scores");
            }}
            className="text-[20px] text-[#B1B1B1] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
          >
            <img src="/icon2a.png" className="w-[19px] h-[24px]" />{" "}
            <div>Submit Scores</div>
          </div>
        )}

        {window.location.pathname === "/dashboard/standings" ? (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/standings");
            }}
            className={
              "text-[20px] text-[#12AFC2] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
            }
          >
            <img src="/icon3b.png" className="w-[19px] h-[24px]" />{" "}
            <div> Standings</div>
          </div>
        ) : (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/standings");
            }}
            className="text-[20px]  text-[#B1B1B1] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
          >
            <img src="/icon3a.png" className="w-[19px] h-[24px]" />{" "}
            <div> Standings</div>
          </div>
        )}

       {user.league_data.on_demand && (
        <>
         {window.location.pathname === "/dashboard/profile" ? (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/profile");
            }}
            className={
              "text-[20px] text-[#12AFC2] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
            }
          >
          <Avatar src={user.avatar} sx={{ width: "40px", height: "40px" }} />{" "}
            <div> Profile</div>
          </div>
        ) : (
          <div
            onClick={() => {
              setOpen(false);
              navigate("/dashboard/profile");
            }}
            className="text-[20px]  text-[#B1B1B1] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
          >
            <Avatar src={user.avatar} sx={{ width: "40px", height: "40px" }} />{" "}
            <div> Profile</div>
          </div>
        )}
        </>
       )}

        <div className="flex-1"></div>

        <div className=" flex justify-center pb-2   ">
          <Button
            sx={{
              bgcolor: "#E6E6E6",
              color: "#244F62",
              width: "150px",
              textTransform: "none",
              borderRadius: "5px",
              fontSize: "15px",
              ":hover": {
                bgcolor: "#E6E6E6",
              },
            }}
            onClick={() => {
              
              window.location.href = "mailto:hello@paddleparty.com";
            }}
          >
            Support
          </Button>
        </div>

        <div className=" flex justify-center pb-5   ">
          <Button
            sx={{
              bgcolor: "#44A093",
              color: "white",
              width: "150px",
              textTransform: "none",
              borderRadius: "5px",
              fontSize: "15px",
              ":hover": {
                bgcolor: "#44A093",
              },
            }}
            onClick={() => {
              localStorage.removeItem("user");
              window.location.pathname = "/";
            }}
          >
            Log Out
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default SideBar;
