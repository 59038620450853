import React from 'react';
import { InputBase } from '@mui/material';

function DateInput({ slot, handleInputChange, index, user }) {
  const today = new Date().toISOString().split('T')[0];
  const endDate = new Date(user.league_data.league_end_date);
  endDate.setDate(endDate.getDate() - 1);
  const maxdate = endDate.toISOString().split('T')[0];
  console.log(user.league_data.league_end_date)

  return (
    <InputBase
      id="datef"
      type="date"
      name="date"
      sx={{
        width: '100%',
        border: '1px solid #44A093',
        bgcolor: 'rgba(68,160,147,0.02)',
        borderRadius: '5px',
        pl: 2,
        py: '1px',
        '&:focus': {
          borderColor: '#44A093', // Set focus border color
          outline: 'none', // Remove default focus outline
        },
      }}
      inputProps={{
        min: today, // Set the minimum date to today
        max: maxdate, // Set the maximum date to tomorrow
      }}
      className="date-input"
      value={slot.date}
      onChange={(e) => handleInputChange(e, index)}
    />
  );
}

export default DateInput;
