import React, { useRef, useEffect } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { InputBase } from '@mui/material';

const AddressAutocomplete = ({ value, onChange, onPlaceSelected }) => {
  const autocompleteRef = useRef(null);

  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.formatted_address) {
        onPlaceSelected(place);
      }
    }
  };

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <InputBase
        value={value}
        onChange={onChange}
        sx={{
          width: '100%',
          border: '1px solid #44A093',
          bgcolor: 'rgba(68,160,147,0.02)',
          borderRadius: '5px',
          pl: 2,
          py: '4px',
        }}
      />
    </Autocomplete>
  );
};

export default AddressAutocomplete;
