import { Button, Modal } from '@mui/material'
import React from 'react'

const ScoreConfirmed = ({open, setOpen}) => {
  return (
    <Modal open={open} onClose={()=> setOpen(false)}   >
        <div className='flex justify-center mt-44'>
        <div className='bg-white border-0 py-4 px-4 lg:w-[40vw] mx-4 lg:mx-0 '>
            <div className='text-center text-[#06B3D2] text-[22px] font-[600] ' >Scores Confirmed</div>
            <div className='text-center text-[18px] text-[#244F62D9] '>
            We’ve got your scores. The rankings page should be updated shortly.  
            </div>

            <div className=" flex justify-center mt-[50px] space-y-4 ">
               
               <Button
                 sx={{
                   bgcolor: "#44A093",
                   color: "white",
                   width: "150px",
                   textTransform: "none",
                   borderRadius: "5px",
                   fontSize: "15px",
                   ":hover": {
                     bgcolor: "#44A093",
                   },
                 }}
                 onClick={()=> {
                    window.location.pathname ='/dashboard/my-matches'
                 }}
               >
                Return Home
               </Button>
           </div>
        </div>
        </div>
    </Modal>
  )
}

export default ScoreConfirmed