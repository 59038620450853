import { Avatar, AvatarGroup, Button, Divider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import ReactHelmet from "react-helmet";
import { Add, ArrowRight, KeyboardArrowRight } from "@mui/icons-material";
import AcceptProposalModal from "../components/AcceptProposalModal";
import Snack from "../components/Snack";
import Maps from "../components/Map";
import SnackRed from "../components/SnackRed";

const ProposalDetail = () => {
  const { proposal_id } = useParams();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [openAcceptProposalModal, setOpenAcceptProposalModal] = useState(false);
  const [matches, setMatches] = useState();
  const [team, setTeam] = useState();
  const [proposal, setProposal] = useState({
    user_id: null,
    team_id: null,
    court_name: "select a court name",
    court_name_id: "",
    court_address: "",
    is_paid: false,
    payment_details: "",
    timeslots: [{ date: "", from: "", to: "", id: 1 }],
  });
  const navigate = useNavigate();
  const getMatchProposal = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/get-proposal/", {
        proposal_id,
      })
      .then((res) => {
        setProposal(res.data.match_proposal);
        getTeam();
      })
      .catch((err) => {});
  };

  const [league, setLeague] = useState();

  const [my_league, setMyLeague] = useState();

  const getProposalLeague = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/get-ranking/", {
        league_id: proposal?.league_id,
      })
      .then((res) => {
        setLeague(res.data.ranking);
        if (!res.data.success) {
          setLeague({ league_name: "No League" });
        }
      })
      .catch((err) => {});
  };

  const getMyLeague = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/get-ranking/", {
        league_id: user.league,
      })
      .then((res) => {
        setMyLeague(res.data.ranking);
        if (!res.data.success) {
          setMyLeague({ league_name: "No League" });
        }
      })
      .catch((err) => {});
  };
  const getTeam = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-team/" + proposal?.team_id)
      .then((res) => {
        setTeam(res.data.team);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getProposalLeague();
  }, [proposal]);
  useEffect(() => {
    if (user) {
      getMatchProposal();
      getMyLeague();
    }
  }, []);
  const avatarStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const [openSnack, setOpenSnack] = useState();
  const [snackMsg, setSnackMsg] = useState();

  //teams

  const [home_team, setHomeTeam] = useState();
  const [away_team, setAwayTeam] = useState();

  const getTeam1 = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-team/" + proposal?.team_id)
      .then((res) => {
        setHomeTeam(res.data.team);
      });
  };

  const getTeam2 = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-team/" + user.team_id)
      .then((res) => {
        setAwayTeam(res.data.team);
      });
  };

  useEffect(() => {
    getTeam1();
  }, [proposal]);

  useEffect(() => {
    getTeam2();
  }, [proposal]);

  const [openSnackRed, setOpenSnackRed] = useState(false);
  const [snackredmsg, setSnackRedMsg] = useState("");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = days[date.getUTCDay()];
    const month = months[date.getUTCMonth()];
    const dayOfMonth = String(date.getUTCDate()).padStart(2, "0"); // Ensure two-digit format

    return `${day} ${month} ${dayOfMonth}`;
  };

  function convertTime24to12(time24) {
    // Split the input time string into hours and minutes
    let [hours, minutes] = time24.split(":");

    // Convert the hours part from string to integer
    hours = parseInt(hours);

    // Determine AM or PM suffix
    let suffix = hours >= 12 ? "PM" : "AM";

    // Adjust hours to 12-hour format
    hours = hours % 12 || 12;

    // Return the formatted time in 12-hour format
    return `${hours}:${minutes} ${suffix}`;
  }

  return (
    <div className="">
      <div className="bg-white rounded-[30px]   overflow-auto lg:w-[700px] px-4 my-8  pb-5">
        <ReactHelmet>
          <title>Proposal Details - Paddle Party</title>
        </ReactHelmet>
        <Snack open={openSnack} setOpen={setOpenSnack} msg={snackMsg} />
        <SnackRed
          open={openSnackRed}
          setOpen={setOpenSnackRed}
          msg={snackredmsg}
        />
        <br />

        {proposal && proposal?.league_id == user.league ? (
          <>
            <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
              Match Proposal
            </div>

            {proposal && proposal.is_open && (
              <div className="w-full text-[#244F62]">
                <div
                  className="flex justify-center"
                  onClick={() => navigate("/dashboard/team/" + home_team._id)}
                >
                  <div className="flex space-x-[-20px] lg:space-x-[-10px]">
                    <div style={avatarStyle}>
                      <img
                        onError={(e) => {
                          e.target.src = "/generic.jpeg";
                        }}
                        src={home_team?.player1.avatar}
                        alt="Avatar"
                        style={imageStyle}
                      />
                    </div>
                    <div style={avatarStyle}>
                      <img
                        onError={(e) => {
                          e.target.src = "/generic.jpeg";
                        }}
                        src={home_team?.player2.avatar}
                        alt="Avatar"
                        style={imageStyle}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="font-[900] text-[20px] text-center"
                  onClick={() => navigate("/dashboard/team/" + home_team._id)}
                >
                  {home_team?.team_name}
                </div>
                <div className="font-[400] text-[18px] mt-2">
                  {proposal.location
                    ? proposal.location.name
                    : proposal.court_name}
                </div>

                <div>
                  {proposal.location ? (
                    <Maps
                      lat={proposal.location.lat}
                      lng={proposal.location.lng}
                      map_url={proposal.location.map_url}
                    />
                  ) : (
                    <Maps address={proposal.court_address} />
                  )}
                </div>

                <div className="mt-2 font-[700]">
                  Payment Details:{" "}
                  {proposal?.is_paid ? proposal?.payment_details : "Free"}
                </div>

                <div className="mt-2 ]">
                  <div className="mt-2 font-[700]">Timeslots:</div>
                  The other team has proposed the following{" "}
                  <span className="font-[700]">start times</span> for the match.
                </div>
                <div className="mt-3 ">
                  {proposal?.timeslots &&
                    proposal?.timeslots
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map((item, index) => {
                        const inputDate = new Date(
                          item.date + "T" + item.from
                        ).getTime();
                        const currentDate = new Date().getTime();

                        if (inputDate > currentDate) {
                          return (
                            <div key={item.id}>
                              {formatDate(item.date)}{" "}
                              {convertTime24to12(item.from)} -{" "}
                              {convertTime24to12(item.to)}
                            </div>
                          );
                        }
                      })}
                </div>
                <AcceptProposalModal
                  open={openAcceptProposalModal}
                  setOpen={setOpenAcceptProposalModal}
                  proposal={proposal}
                  setOpenSnack={setOpenSnack}
                  setSnackMsg={setSnackMsg}
                  home_team={home_team}
                  away_team={away_team}
                  setProposal={setProposal}
                  setOpenSnackRed={setOpenSnackRed}
                  setSnackRedMsg={setSnackRedMsg}
                />

                {proposal.is_accepted ? (
                  "proposal was accepted"
                ) : (
                  <div className="lg:px-28 mt-4">
                    {proposal.team_id != user.team_id ? (
                      <Button
                        sx={{
                          bgcolor: "#12AFC2",
                          color: "white",
                          width: "100%",
                          textTransform: "none",
                          borderRadius: "5px",
                          fontSize: "18px",
                          fontWeight: "bold",
                          ":hover": {
                            bgcolor: "#12AFC2",
                          },
                        }}
                        onClick={() => {
                          setOpenAcceptProposalModal(true);
                        }}
                      >
                        Accept Match Proposal
                      </Button>
                    ) : (
                      <div className="text-center">
                        {" "}
                        You cannot accept this match{" "}
                      </div>
                    )}
                  </div>
                )}

                {!proposal && (
                  <div className=" text-center text-[14px] lg:text-[19px]">
                    <div className="font-bold"> No Proposals</div>
                    There aren’t any proposals at the moment. Create a new one
                    using the links above.
                  </div>
                )}
              </div>
            )}

            {proposal && !proposal.is_open && (
              <div className="w-full text-[#244F62]">
                <div className="flex justify-center">
                  <div className="flex space-x-[-20px] lg:space-x-[-10px]">
                    <div style={avatarStyle}>
                      <img
                        onError={(e) => {
                          e.target.src = "/generic.jpeg";
                        }}
                        src={home_team?.player1.avatar}
                        alt="Avatar"
                        style={imageStyle}
                      />
                    </div>
                    <div style={avatarStyle}>
                      <img
                        onError={(e) => {
                          e.target.src = "/generic.jpeg";
                        }}
                        src={home_team?.player2.avatar}
                        alt="Avatar"
                        style={imageStyle}
                      />
                    </div>
                  </div>
                </div>
                <div className="font-[900] text-[20px] text-center">
                  {home_team?.team_name}
                </div>

                <div className="mt-3 text-[#244F62D9]">
                  <div className="text-center font-bold text-[18px] ">
                    This proposal is no longer available
                  </div>

                  <div className="text-center font-normal text-[15px]  mt-2">
                    Still want to play? View other proposals or create your own
                    proposal below.
                  </div>

                  <div className="px-5 lg:px-0 my-5">
                    <Button
                      sx={{
                        bgcolor: "#E4E4E4",
                        color: "white",
                        width: "100%",
                        textTransform: "none",
                        borderRadius: "5px",
                        fontSize: { lg: "22px", xs: "15px" },
                        fontWeight: "bold",
                        ":hover": {
                          bgcolor: "#E4E4E4",
                        },
                      }}
                      onClick={() => {
                        navigate("/dashboard/open-proposals");
                      }}
                    >
                      View Other Proposed Matches
                    </Button>
                  </div>

                  <div className="lg:px-28 px-5">
                    <Button
                      sx={{
                        bgcolor: "#12AFC2",
                        color: "white",
                        width: "100%",
                        textTransform: "none",
                        borderRadius: "5px",
                        fontSize: { lg: "22px", xs: "18px" },
                        fontWeight: "bold",
                        ":hover": {
                          bgcolor: "#12AFC2",
                        },
                      }}
                      onClick={() => {
                        navigate("/dashboard/create-new-match-proposal");
                      }}
                      startIcon={<img src="/plus.png" className="w-[20px]" />}
                    >
                      New Match Proposal
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="mt-3 text-[#244F62D9]">
            <div className="text-center font-bold text-[18px] ">
              Cannot Access Proposal
            </div>

            <div className="text-center font-normal text-[15px]  mt-2">
              This proposal is in the{" "}
              {league?.league_name + "-" + league?.league_subtext}. You are only
              able to access matches in the{" "}
              {my_league?.league_name + "-" + my_league?.league_subtext}
            </div>

            <div className="px-5 lg:px-0 my-5">
              <Button
                sx={{
                  bgcolor: "#E4E4E4",
                  color: "white",
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: { lg: "22px", xs: "15px" },
                  fontWeight: "bold",
                  ":hover": {
                    bgcolor: "#E4E4E4",
                  },
                }}
                onClick={() => {
                  navigate("/dashboard/open-proposals");
                }}
              >
                View Other Proposed Matches
              </Button>
            </div>

            <div className="lg:px-28 px-5">
              <Button
                sx={{
                  bgcolor: "#12AFC2",
                  color: "white",
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: { lg: "22px", xs: "18px" },
                  fontWeight: "bold",
                  ":hover": {
                    bgcolor: "#12AFC2",
                  },
                }}
                onClick={() => {
                  navigate("/dashboard/create-new-match-proposal");
                }}
                startIcon={<img src="/plus.png" className="w-[20px]" />}
              >
                New Match Proposal
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProposalDetail;
