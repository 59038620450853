import React from 'react';
import AddressAutocomplete from './Autocomplete';

const CourtAddressField = ({ proposal, setProposal }) => {
  const handleAddressChange = (event) => {
    setProposal({ ...proposal, court_address: event.target.value });
  };

  const handlePlaceSelected = (place) => {
    setProposal({ ...proposal, court_address: place.formatted_address });
  };

  return (
    <div>
      <AddressAutocomplete
        value={proposal.court_address}
        onChange={handleAddressChange}
        onPlaceSelected={handlePlaceSelected}
      />
    </div>
  );
};

export default CourtAddressField;
