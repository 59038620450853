import { Cancel } from "@mui/icons-material";
import { Button, Checkbox, Modal } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SnackRed from "./SnackRed";

const ConfirmNewProposalModal = ({ open, setOpen , proposal, setProposal, setOpenSnack, setSnackMsg ,setSelectedValue,setOpenSnackRed,setSnackRedMsg}) => {
    
  const [checked, setChecked] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const navigate = useNavigate()
  


  const validateTimeslot = (timeslot) => {

    // Get current date and time
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().slice(0, 10); // Get YYYY-MM-DD format
  
    // Extract values from timeslot
    const { date, from, to } = timeslot;
  
    // Validate conditions
    if (date < currentDateString) {
      return false; // Date is in the past
    }
  
    // Check time validity only if date is today or future
    if (date >= currentDateString) {
      // Combine date and time for comparison
      const fromDateTime = new Date(`${date}T${from}`);
      const toDateTime = new Date(`${date}T${to}`);
  
      // Ensure `to` is later than `from` within the same day
      if (toDateTime <= fromDateTime) {
        return false; // `to` is not beyond `from`
      }
    }
  
    return true; // All conditions pass
  };




  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    const day = days[date.getUTCDay()];
    const month = months[date.getUTCMonth()];
    const dayOfMonth = String(date.getUTCDate()).padStart(2, '0'); // Ensure two-digit format
  
    return `${day} ${month} ${dayOfMonth}`;
  };
  

  const submitProposal = async () => {

    proposal.timeslots.map((item,index)=> {
      if(!validateTimeslot(item)){

        setOpenSnackRed(true)
        setSnackRedMsg('Error: Please review your timeslots')
      }
    })
  
    console.log(proposal)
    await axios.post(process.env.REACT_APP_BACKEND_URL+'/create-proposal', proposal)
    .then((res)=> {
        console.log(res.data)
        setSelectedValue('no')
        setProposal({
            league_id : user.league,
            user_id : user._id,
            team_id : user.team_id,
            court_name: "select a court name",
            court_name_id: "",
            court_address: "",
            is_paid: false,
            payment_details: "",
            timeslots: [{ date: "", from: "", to: "", id: 1 }],
          })
        setOpen(false)
        setOpenSnack(true)
        setSnackMsg('New Match Proposal Created')
        setTimeout(() => {
          navigate('/dashboard/manage-match-proposals')
        }, 2000);
        
        
    })
  }


  function convertTime24to12(time24) {
    // Split the input time string into hours and minutes
    let [hours, minutes] = time24.split(':');
  
    // Convert the hours part from string to integer
    hours = parseInt(hours);
  
    // Determine AM or PM suffix
    let suffix = hours >= 12 ? 'PM' : 'AM';
  
    // Adjust hours to 12-hour format
    hours = hours % 12 || 12;
  
    // Return the formatted time in 12-hour format
    return `${hours}:${minutes} ${suffix}`;
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
    
      <div className="flex justify-center lg:mt-20 mt-5 text-[#244F62D9] ">
        <div className="bg-white border-0 py-4 px-4 lg:w-[40vw] mx-4 lg:mx-0  rounded-[20px] relative">
            <Cancel
            sx={{ color:"gray", position:'absolute', top: 4, right: 4, cursor:'pointer'}}
            onClick={()=> {
                setOpen(false)
            }}
            />
          <div className="text-center text-[#06B3D2] text-[22px] font-[600] ">
            Confirm Proposal
          </div>
          <div className="space-y-4">
            <div className=" text-[16px] lg:text-[18px] text-[#244F62D9] ">
              You’re all ready to submit your proposal. Here are the details you
              provided:
            </div>

            <div className=" text-[16px] lg:text-[18px] text-[#244F62D9] ">
              <span className="font-bold">Court: </span>{proposal.court_name}
            </div>

            <div className=" text-[16px] lg:text-[18px] text-[#244F62D9] ">
              <div className="font-bold">Timeslots:</div>
              {proposal.timeslots && proposal.timeslots.map((item, index)=> (
                <div key={item.id} >{formatDate(item.date)} {convertTime24to12(item.from)} - {convertTime24to12(item.to)}</div>
              ))}
              {/* <div>Sun May 29 10:00 AM - 2:00 PM</div> */}
            </div>

            <div className="mt-4">
              Have you confirmed these times work for your partner also?
            </div>
          </div>

          <div className="flex items-center mt-3">
            {" "}
            <div>
              <Checkbox
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
            </div>
            <div>
            Yes I’ve confirmed these times work with my partner.{" "}
            </div>
          </div>

          {checked ? (
            <div className=" flex justify-center mt-[50px] ">
              <Button
                sx={{
                  bgcolor: "#44A093",
                  color: "white",
                  width: "150px",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "15px",
                  ":hover": {
                    bgcolor: "#44A093",
                  },
                }}
                onClick={submitProposal}
              >
                Confirm
              </Button>
            </div>
          ) : (
            <div className=" flex justify-center mt-[50px] ">
              <Button
                sx={{
                  bgcolor: "#44A093",
                  color: "gray",
                  width: "150px",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "15px",
                  ":hover": {
                    bgcolor: "gray",
                  },
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmNewProposalModal;
