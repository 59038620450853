import { Button, Modal } from "@mui/material";
import React from "react";

const ScoreRejectedModal = ({ open, setOpen, match_id }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="flex justify-center mt-44">
        <div className="bg-white border-0 py-4 px-4 lg:w-[40vw] mx-4 lg:mx-0 ">
          <div className="text-center text-[#06B3D2] text-[22px] font-[600] ">
            Scores Rejected
          </div>
          <div className="text-center text-[18px] text-[#244F62D9] ">
            You rejected the score report for this bonus match. If the match was
            played but is incorrect, please resubmit the scores.
          </div>

          <div className=" flex justify-center mt-[50px] space-x-4 ">
            <div>
              <Button
                sx={{
                  bgcolor: "#E6E6E6",
                  color: "#244F62",
                  width: "100px",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "15px",
                  ":hover": {
                    bgcolor: "#E6E6E6",
                  },
                }}
                onClick={() => {
                  window.location.pathname = "/dashboard/my-matches";
                }}
              >
                Support
              </Button>
            </div>

            <div>
            <Button
              sx={{
                bgcolor: "#44A093",
                color: "white",
                width: "150px",
                textTransform: "none",
                borderRadius: "5px",
                fontSize: "15px",
                ":hover": {
                  bgcolor: "#44A093",
                },
              }}
              onClick={() => {
                window.location.pathname =
                  "/dashboard/submit-extra-match/" + match_id;
              }}
            >
              Submit Scores
            </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ScoreRejectedModal;
