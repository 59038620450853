import { Cancel } from "@mui/icons-material";
import { Button, Checkbox, Modal } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CancelProposalModal = ({ open, setOpen, proposal,setOpenSnack,setSnackMsg }) => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate()
  const cancelProposal = async() => {
    console.log(proposal)
    let newProposal = {...proposal,is_cancelled : true, is_open: false}
    await axios.post(process.env.REACT_APP_BACKEND_URL+'/cancel-proposal', newProposal)
    .then((res)=> {
        console.log(res.data)
        setOpen(false)
        setOpenSnack(true)
        setSnackMsg(' Match Proposal Updated')
        setTimeout(() => {
          navigate('/dashboard/manage-match-proposals')
        }, 2000);
        
    })
  }
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="flex justify-center lg:mt-20 mt-5 text-[#244F62D9] ">
        <div className="bg-white border-0 py-4 px-4 lg:w-[40vw] mx-4 lg:mx-0  rounded-[20px] relative">
            <Cancel
            sx={{ color:"gray", position:'absolute', top: 4, right: 4, cursor:'pointer'}}
            onClick={()=> {
                setOpen(false)
            }}
            />
          <div className="text-center text-[#06B3D2] text-[22px] font-[600] ">
            Cancel Proposal
          </div>
          <div className="space-y-4">
            <div className=" text-[16px] lg:text-[18px] text-[#244F62D9] ">
            Are you sure you’d like to cancel this proposal? Players will no longer be able to accept it.
            </div>

          </div>

        

            <div className=" flex justify-center mt-[50px] ">
              <Button
                sx={{
                  bgcolor: "#44A093",
                  color: "white",
                  width: "150px",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "15px",
                  ":hover": {
                    bgcolor: "#44A093",
                  },
                }}
                onClick={cancelProposal}
                
              >
                Confirm
              </Button>
            </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelProposalModal;
