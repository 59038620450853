import React, { useEffect, useState } from "react";
import { LocationOn } from "@mui/icons-material";
import axios from "axios";

const Component = ({ text }) => (
  <div className="text-white font-semibold text-center">
    {" "}
    <div>
      {" "}
      <LocationOn sx={{ color: "#1ebf99", fontSize: "3rem" }} />
    </div>{" "}
  </div>
);







const Maps = ({ lat, lng, address , map_url}) => {
  const [isMapValid, setIsMapValid] = useState(true);
 
  const apiKey = 'AIzaSyCpcCXbvc5MpwEijvRHN5DLLPxfIUx47ic';


  useEffect(() => {
    // Function to check if the map URL is valid
    const checkMapValidity = async () => {
      try {
        const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&addressdetails=1&limit=1`;
        const response = await axios.get(url);
    
        if (response.data && response.data.length > 0) {
          console.log(response.data[0])
          setIsMapValid(true)
        } else {
          console.log(response)
          setIsMapValid(false)
          
        }
      } catch (error) {
        console.error('Error validating location:', error);
        setIsMapValid(false)
      }
    };

    checkMapValidity();
  }, []);


  
  const defaultProps = {
    center: {
      lat,
      lng,
    },
    zoom: -100,
  };
  return (
    <div style={{ width: "100%" }}>
      {/* <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCpcCXbvc5MpwEijvRHN5DLLPxfIUx47ic', version: '3.31' }}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                
                 
                
            >
                <Component
                lat = {lat}
                lng = {lng} 
                text = "property"
                />
 
            </GoogleMapReact> */}

      {address ? (
        <>
          <div className="lg:hidden"
          onClick={() => {
            const encodedAddress = encodeURIComponent(address);
            let url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
            // window.open(`https://www.google.com/maps/place/${encodeURIComponent(
            //     address
            //   )}&zoom=14&size=400x330&markers=color:0x1ebf99%7C${encodeURIComponent(
            //     address
            //   )}&key=AIzaSyCpcCXbvc5MpwEijvRHN5DLLPxfIUx47ic`, '_blank')

            window.open(url, '_blank');
        }}
          >
           {isMapValid ? (
             <img
             src={`https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
               address
             )}&zoom=14&size=400x330&markers=color:0x1ebf99%7C${encodeURIComponent(
               address
             )}&key=AIzaSyCpcCXbvc5MpwEijvRHN5DLLPxfIUx47ic`}
             alt="Google Map"
           />
           ) : (
            <div className="text-center">Unable to load map. Address is {address} </div>
           )}
          </div>

          <div className="lg:block  hidden"
          
          onClick={() => {
            const encodedAddress = encodeURIComponent(address);
            let url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
            // window.open(`https://www.google.com/maps/place/${encodeURIComponent(
            //     address
            //   )}&zoom=14&size=400x330&markers=color:0x1ebf99%7C${encodeURIComponent(
            //     address
            //   )}&key=AIzaSyCpcCXbvc5MpwEijvRHN5DLLPxfIUx47ic`, '_blank')

            window.open(url, '_blank');}
        }
        
        >
           {isMapValid ? (
             <img
             src={`https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
               address
             )}&zoom=14&size=900x215&markers=color:0x1ebf99%7C${encodeURIComponent(
               address
             )}&key=AIzaSyCpcCXbvc5MpwEijvRHN5DLLPxfIUx47ic`}
             alt="Google Map"
           />
           ): (
            <div className="text-center">Unable to load map. Address is {address}</div>
           )}
          </div>
        </>
      ) : (
        <>
          <div className="lg:hidden"
          
          onClick={() => {
            if(map_url){
              window.open(map_url, '_blank')
            }else{
              window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank')
            }
          }
            
        }>
          
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=14&size=400x330&markers=color:0x1ebf99%7C${lat},${lng}&key=AIzaSyCpcCXbvc5MpwEijvRHN5DLLPxfIUx47ic`}
            />
          </div>

          <div className="lg:block hidden"
          
          onClick={() => 

          {
            if(map_url){
              window.open(map_url, '_blank')
            }else{
              window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank')
            }
           
          }
        }
        
        >
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=14&size=900x215&markers=color:0x1ebf99%7C${lat},${lng}&scale=2&key=AIzaSyCpcCXbvc5MpwEijvRHN5DLLPxfIUx47ic`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Maps;
