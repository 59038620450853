import { Avatar, AvatarGroup, Button, Divider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import ReactHelmet from "react-helmet";
import { Add, ArrowRight, KeyboardArrowRight } from "@mui/icons-material";

const ManageMatchProposals = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [matches, setMatches] = useState();
  const [match_proposals, setMatchProposals] = useState([]);
  const navigate = useNavigate();
  const getProposals = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/get-proposals/", {
        user_id: user._id,
      })
      .then((res) => {
        console.log(res.data);
        setMatchProposals(res.data.match_proposals);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      getProposals();
    }
  }, []);
  const avatarStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  function getDayOfWeek(dateString) {
    const date = new Date(dateString);
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayOfWeek = date.getUTCDay(); // getUTCDay returns the day of the week (0 for Sunday, 1 for Monday, etc.)
    return daysOfWeek[dayOfWeek];
  }

  function getFormattedDate(dateString) {
    const date = new Date(dateString);
    const month = date.getUTCMonth() + 1; // getUTCMonth() returns month from 0-11, so we add 1
    const day = date.getUTCDate();
    return `${month}/${day}`;
  }

  return (
    <div className="bg-white rounded-[30px]   overflow-auto lg:w-[700px] px-4 my-8  pb-5">
      <ReactHelmet>
        <title>Manage match proposals - Paddle Party</title>
      </ReactHelmet>

      <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
        My Proposed Matches
      </div>

      <div className="lg:px-28 mb-4">
        <Button
          sx={{
            bgcolor: "#12AFC2",
            color: "white",
            width: "100%",
            textTransform: "none",
            borderRadius: "5px",
            fontSize: "18px",
            fontWeight: "bold",
            ":hover": {
              bgcolor: "#12AFC2",
            },
          }}
          onClick={() => {
            navigate("/dashboard/create-new-match-proposal");
          }}
          startIcon={<img src="/plus.png" className="w-[18px]" />}
        >
          New Match Proposal
        </Button>
      </div>

      {match_proposals &&
        match_proposals.map((proposal, index) => {
          return (
            <div className="w-full text-[#244F62]"
            onClick={() => {
              if( proposal.is_accepted ) {
                navigate("/dashboard/match/"+proposal.match_id);
              }
             }}
            >
              <div
                className="flex items-start"
                
              >
                <div className="flex-1">
                  <div className="font-[900] text-[20px]"
                  onClick={() => {
                   if( proposal.is_open ) {
                    navigate("/dashboard/manage-match-proposal/"+proposal._id);
                   }
                  }}
                  >
                    {proposal.court_name}
                  </div>

                  <div className="flex items-center space-x-4 mt-2 w-[180px] lg:w-full  overflow-x-auto"
                  onClick={() => {
                    if( proposal.is_open ) {
                      navigate("/dashboard/manage-match-proposal/"+proposal._id);
                     }
                  }}
                  >
                    {proposal.timeslots.map((slot, index) => (
                      <div className="bg-[#EEF1F5] rounded-full px-4 py-2 ">
                        <div className="font-bold">{getDayOfWeek(slot.date)}</div>
                        <div>{getFormattedDate(slot.date)}</div>
                      </div>
                    ))}

                   
                  </div>
                </div>

                <div className=" mt-[20px] space-y-2">
                  {proposal.is_open && (
                    <div className="bg-[#E6E6E6] w-fit px-5 py-1 rounded-[10px] font-semibold">
                      Open
                    </div>
                  )}

                  {proposal.is_cancelled && (
                    <div className="bg-[#CC0000] text-white w-fit px-5 py-1 rounded-[10px] font-semibold">
                      Cancelled
                    </div>
                  )}

                  {proposal.is_accepted && (
                    <div className="bg-[#3DB44B] text-white w-fit px-5 py-1 rounded-[10px] font-semibold">
                      Accepted
                    </div>
                  )}
                 {proposal.is_open && (
                   <div>
                   <KeyboardArrowRight
                     sx={{ color: "#FEC901", fontSize: "60px", cursor:'pointer' }}
                     onClick={() => {
                      if( proposal.is_open ) {
                        navigate("/dashboard/manage-match-proposal/"+proposal._id);
                       }else{
                        navigate("/dashboard/proposal-details/"+proposal._id);
                       }
                     
                    }}
                   />
                 </div>
                 )}
                </div>
              </div>

              <Divider sx={{ my: 2 }} />
            </div>
          );
        })}

      {!match_proposals && (
        <div className=" text-center text-[14px] lg:text-[19px]">
          <div className="font-bold"> No Proposals</div>
          There aren’t any proposals at the moment. Create a new one using the
          links above.
        </div>
      )}
    </div>
  );
};

export default ManageMatchProposals;
