import { Avatar, AvatarGroup, Divider, Tooltip } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import ReactHelmet from 'react-helmet';
import { Info } from "@mui/icons-material";

const TeamDetails = () => {
  const [team, setTeam] = useState();
  const [loader, setLoader] = useState(true);
  const { team_id } = useParams();
  const navigate = useNavigate();
  const getTeam = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-team/" + team_id)
      .then((res) => {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        setTeam(res.data.team);
        console.log(res.data.team);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTeam();
  }, [team_id]);

  const avatarStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <>
    <ReactHelmet>
        <title>Team Details - Paddle Party</title>
      </ReactHelmet>
      {!loader && team && (
        <div className="bg-white rounded-[30px] lg:w-[700px] px-4 my-8    overflow-auto ">
          <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
            Team Details
          </div>

          <div>
            <div className="flex justify-center">
              <AvatarGroup total={2} spacing={20}>
                <Avatar
                  alt={team.player1.first_name}
                  src={team.player1.avatar || "/generic.jpeg"}
                  onError={(e) => {
                    e.target.src = "/generic.jpeg";
                  }}
                  className="object-cover"
                  sx={{ width: "100px", height: "100px" }}
                />
                <Avatar
                  alt={team.player2.first_name}
                  src={team.player2.avatar || "/generic.jpeg"}
                  className="object-cover"
                  sx={{ width: "100px", height: "100px" }}
                />
              </AvatarGroup>
            </div>

            <div className=" lg:text-[24px] text-[16px] text-center my-3 text-[#244F62D9] font-[400]">
              {team.team_name}
              {!team.matches_ || (team.matches_.length < 1 && " UR")}
            </div>
            <div className=" lg:text-[17px] text-[13px] relative flex justify-center space-x-6 text-center my-1 text-[#244F62] font-bold">
             <div className=" relative right-1 ">Record
             <Tooltip title="The results of the matches this team has played"
              disableTouchListener={false} // Enable touch listener for mobile devices
              disableHoverListener={false} // Ensure hover listener is enabled for desktops
              enterTouchDelay={0} // Make sure tooltip appears instantly on touch
              leaveTouchDelay={3000}
             >
                    <Info style={{ marginLeft: '0.5rem', fontSize: '16px', cursor: 'pointer' }} />
                </Tooltip>
             </div>  <div>{team.wins} - {team.losses}</div>
            </div>

            <div className=" lg:text-[17px] text-[13px] flex justify-center space-x-6 text-center mt-1 mb-3 text-[#244F62] font-bold">
             <div className=" ">Rank Points
             <Tooltip title="The total rank points that this team has earned. For more details, refer to the league guide."
             
             disableTouchListener={false} // Enable touch listener for mobile devices
             disableHoverListener={false} // Ensure hover listener is enabled for desktops
             enterTouchDelay={0} // Make sure tooltip appears instantly on touch
             leaveTouchDelay={3000}
             
             >
                    <Info style={{ marginLeft: '0.5rem', fontSize: '16px', cursor: 'pointer' }} />
                </Tooltip>
             </div>  <div>{team.rank_pts || 0}</div>
            </div>
          </div>

          <Divider sx={{ my: 1 }} />

          <div className="hidden lg:grid grid-cols-9 w-full my-3 items-center">
            <div className="col-span-2"></div>
            <div className="col-span-3 text-[18px] text-[#06B3D2] font-[400]">
              Team
            </div>
            <div className="col-span-2 text-[18px] text-[#06B3D2] font-[400]">
              Score
            </div>
            <div className="col-span-2 text-[18px] text-[#06B3D2] font-[400]">
              Result
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-1"></div>
          </div>

          {team.matches_.map((item, index) => {
            let other_team_id = item.teams
              .filter((e) => e.team_id !== team_id)
              .map((e) => e.team_id);
            console.log(other_team_id[0]);
            const getTeam1 = async () => {
              await axios
                .get(
                  process.env.REACT_APP_BACKEND_URL +
                    "/get-team/" +
                    other_team_id[0]
                )
                .then((res) => {
                  document.getElementById(index + "-1").src =
                    res.data.team.player1.avatar || "/generic.jpeg";
                  document.getElementById(index + "-2").src =
                    res.data.team.player2.avatar || "/generic.jpeg";
                  document.getElementById(index + "-1").alt =
                    res.data.team.player1.first_name;
                  document.getElementById(index + "-2").alt =
                    res.data.team.player2.first_name;
                  if (
                    !res.data.team.matches_ ||
                    res.data.team.matches_.length < 1
                  ) {
                    document.getElementById("ranking"+index).innerHTML = "UR";
                    document.getElementById("rankingsm"+index).innerHTML = "UR";
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            };
            getTeam1();
            return (
              <div>
                <div className="grid lg:grid-cols-9 grid-cols-9 gap-4 lg:gap-0 w-full items-center">
                  <div className="col-span-2 flex items-center">
                    <div className="text-[16px] text-[#06B3D2] font-[500]">
                      {item.week_number}.
                    </div>
                    <div className="flex space-x-[-20px] lg:space-x-[-10px]">
                      <div style={avatarStyle}>
                        <img
                          onError={(e) => {
                            e.target.src = "/generic.jpeg";
                          }}
                          id={index + "-1"}
                          src={""}
                          alt="Avatar"
                          style={imageStyle}
                        />
                      </div>
                      <div style={avatarStyle}>
                        <img
                          onError={(e) => {
                            e.target.src = "/generic.jpeg";
                          }}
                          id={index + "-2"}
                          src={""}
                          alt="Avatar"
                          style={imageStyle}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-span-3 lg:text-[20px] text-[16px] hidden lg:block text-[#244F62] font-[400] cursor-pointer"
                    onClick={() => {
                      setLoader(true);
                      navigate(
                        "/dashboard/team/" +
                          item.teams
                            .filter((e) => e.team_id !== team_id)
                            .map((e) => e.team_id)
                      );
                    }}
                  >
                    {item.teams
                      .filter((e) => e.team_id !== team_id)
                      .map((e) =>
                        e.team_name.length > 14
                          ? `${e.team_name.slice(0, 14)}...`
                          : e.team_name
                      )}
                      {" "}
                    <span id={"ranking"+index}></span>
                  </div>
                  <div className="col-span-2 lg:text-[20px] text-[16px] hidden lg:block text-[#244F62] font-[400]">
                    {!item.is_forfeit && item.score
                      ? item.score.map((item, index) => {
                          let otherTeamId = Object.keys(item.game1).find(
                            (teamId) => teamId != team_id
                          );

                          return (
                            <>
                              <>
                                {item.game1 &&
                                  item.game1[team_id] +
                                    "-" +
                                    item.game1[otherTeamId]}
                              </>
                              <>
                                {item.game2 &&
                                  ", " +
                                    item.game2[team_id] +
                                    "- " +
                                    item.game2[otherTeamId]}
                              </>
                              <>
                                {item.game3 &&
                                  ", " +
                                    item.game3[team_id] +
                                    "-" +
                                    item.game3[otherTeamId]}
                              </>
                            </>
                          );
                        })
                      : ""}
                      {item.is_forfeit && "Forfeit"}
                  </div>

                  <div className="col-span-5 lg:text-[24px] text-[18px] pl-2 lg:hidden text-[#244F62] font-[400]">
                    <div
                      onClick={() => {
                        setLoader(true);
                        navigate(
                          "/dashboard/team/" +
                            item.teams
                              .filter((e) => e.team_id !== team_id)
                              .map((e) => e.team_id)
                        );
                      }}
                      className="cursor-pointer"
                    >
                      {item.teams
                        .filter((e) => e.team_id !== team_id)
                        .map((e) =>
                          e.team_name.length > 14
                            ? `${e.team_name.slice(0, 14)}...`
                            : e.team_name
                        )} 
                        {" "}
                         <span id={"rankingsm"+index}></span>
                    </div>
                    <div className="text-[16px]">
                      {!item.is_forfeit && item.score
                        ? item.score.map((item, index) => {
                            let otherTeamId = Object.keys(item.game1).find(
                              (teamId) => teamId != team_id
                            );

                            return (
                              <>
                                <>
                                  {item.game1 &&
                                    item.game1[team_id] +
                                      "-" +
                                      item.game1[otherTeamId]}
                                </>
                                <>
                                  {item.game2 &&
                                    ", " +
                                      item.game2[team_id] +
                                      "- " +
                                      item.game2[otherTeamId]}
                                </>
                                <>
                                  {item.game3 &&
                                    ", " +
                                      item.game3[team_id] +
                                      "-" +
                                      item.game3[otherTeamId]}
                                </>
                              </>
                            );
                          })
                        : ""}
                        {item.is_forfeit && "Forfeit"}
                    </div>
                  </div>
                  <div className="col-span-2 lg:text-[20px] text-[12px] text-white font-[500]">
                  {item.winning_team &&
                    item.winning_team === team_id &&
                    !item.is_forfeit && (
                      <div className="bg-[#3DB44B] w-fit rounded-[5px] px-4 ">
                        Win
                      </div>
                    )}

                  {item.winning_team &&
                    item.winning_team === team_id &&
                    item.is_forfeit && (
                      <div className="bg-[#45818E] w-fit rounded-[5px] px-4 ">
                        Win
                      </div>
                    )}

                  {item.winning_team &&
                    item.winning_team != team_id &&
                    !item.is_forfeit && (
                      <div className="bg-[#CC0000] w-fit rounded-[5px] px-4 ">
                        Loss
                      </div>
                    )}

                  {item.winning_team &&
                    item.winning_team != team_id &&
                    item.is_forfeit && (
                      <div className="bg-[#CC0000] w-fit rounded-[5px] px-4 ">
                        Loss
                      </div>
                    )}

                  {!item.is_forfeit && !item.winning_team && (
                    <div className="bg-[#E6E6E6] w-fit rounded-[5px] px-2 ">
                      Unplayed
                    </div>
                  )}

                  { item.is_forfeit && !item.winning_team && (
                    <div className="bg-[#9c9a9a] w-fit rounded-[5px] px-2 ">
                      Unplayed
                    </div>
                  )}


<div className="text-[#244F62] text-[16px]">    +{item.rank_pts ? item.rank_pts[team._id] : 0} Pts </div>
                
                  
                  </div>

                  
                </div>

                

                

                <Divider sx={{ my: 1 }} />
              </div>
            );
          })}
        </div>
      )}
      {loader && (
        <div className="mt-4">
          <Loader />
        </div>
      )}

      {!loader && !team && <div className="mt-4">No team found</div>}
    </>
  );
};

export default TeamDetails;
