import { Avatar, AvatarGroup, Divider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import ReactHelmet from 'react-helmet';

const Standings = () => {
  const [rankings, setRankings] = useState();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [loader, setLoader] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();

  const getRanking = async () => {
    setLoader(true);
    console.log(user.league);
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/get-ranking/", {
        league_id: user.league,
      })
      .then((res) => {
        if (res.data.success) {
          let rankings = res.data.ranking.rankings
          console.log(
            res.data.ranking
          );
          const filteredRankings = rankings.sort((a, b) => {
            if (a.rank === null && b.rank === null) {
              return 0;
            }
            if (a.rank === null) {
              return 1; // a should come after b
            }
            if (b.rank === null) {
              return -1; // a should come before b
            }
            return a.rank - b.rank;
          });
          
          // Sort teams array based on the sorted rankings
          const sortedTeams = filteredRankings.map(({ team_id }) => res.data.ranking.teams.find(team => team._id === team_id));

          setRankings({
            league_name : res.data.ranking.league_name,
            league_name : res.data.ranking.league_subtext,
            _id :  res.data.ranking._id,
            teams: sortedTeams,
            rankings:  filteredRankings
          })

          console.log(sortedTeams)
        } else {
          setErrorMsg(res.data.msg);
        }
        setLoader(false);
      })

      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getRanking();
  }, []);

  return (
    <>
     <ReactHelmet>
        <title>Standings - Paddle Party</title>
      </ReactHelmet>
      {!loader ? (
        <>
          {errorMsg && <div>{errorMsg}</div>}

          {rankings && (
            <div className="bg-white rounded-[30px] overflow-auto lg:w-[700px] px-4 my-8 ">
              <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
                Standings
              </div>

              <div className=" lg:text-[24px] text-[16px] text-center my-3 text-[#244F62D9] font-[400]">
                {rankings?.league_name}
              </div>

              <div className=" lg:text-[20px] text-[14px] text-center my-3 text-[#244F62D9] font-[400]">
                {rankings?.league_subtext}
              </div>

              <div className="hidden lg:grid grid-cols-7 w-full my-3 lg:px-10">
                <div className="col-span-2 text-[18px] text-[#06B3D2] font-[400]">
                  Rank
                </div>
                <div className="col-span-3 text-[18px] text-[#06B3D2] font-[400]">
                  Team
                </div>
                <div className="col-span-2 text-[18px] text-[#06B3D2] font-[400]">
                  Score
                </div>
              </div>

              {rankings &&
                rankings.teams.map((item, index) => {
                  return (
                    <>
                      <div
                        className="grid lg:grid-cols-7 grid-cols-7 gap-4 items-center lg:gap-0 w-full lg:px-10 cursor-pointer"
                        onClick={() => {
                          navigate("/dashboard/team/" + item._id);
                        }}
                      >
                        <div className="col-span-2 flex items-center lg:space-x-7">
                          <div className="text-[16px] text-[#06B3D2] font-[500]">
                          {rankings.rankings[index].rank === null ? 'UR' : (rankings.rankings[index].rank)}
                          </div>
                          <AvatarGroup total={2} spacing={20}>
                            <Avatar
                              alt={item.player1.first_name}
                              src={item.player1.avatar || "/generic.jpeg"}
                              className="object-cover"
                              sx={{ width: "40px", height: "40px" }}
                            />
                            <Avatar
                              alt={item.player2.first_name}
                              src={item.player2.avatar || "/generic.jpeg"}
                              className="object-cover"
                              sx={{ width: "40px", height: "40px" }}
                            />
                          </AvatarGroup>
                        </div>
                        <div
                          className="col-span-3 lg:text-[24px] text-[16px]  lg:block text-[#244F62] font-[400] cursor-pointer hover:text-[#244F62D9]"
                          onClick={() => {
                            navigate("/dashboard/team/" + item._id);
                          }}
                        >
                          {item.team_name.length > 14
                            ? `${item.team_name.slice(0, 14)}...`
                            : item.team_name}
                            {" "}

                            
                        </div>
                        <div className="col-span-2 lg:text-[24px] text-[16px]  lg:block text-[#244F62] font-[400]">
                          {item.wins}W - {item.losses}L
                        </div>
                      </div>
                      {index == rankings.teams.length - 1 ? null : (
                        <Divider sx={{ my: 1 }} />
                      )}
                    </>
                  );
                })}
            </div>
          )}
        </>
      ) : (
        <div className="mt-4">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Standings;
