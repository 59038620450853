import { Home } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Snack from "../components/Snack";
import ScoreSubmittedModal from "../components/ScoreSubmittedModal";
import TokenFailed from "../components/TokenFailed";
import ReactHelmet from "react-helmet";
import ExtraMatchScoreSubmitedModal from "../components/ExtraMatchScoreSubmittedModal";

const UpdateScores = ({ extraMatch }) => {
  const [loader, setLoader] = useState(true);
  const [focus_, setFocus] = useState(false);
  const [win, setWin] = useState(null);

  const [draw, setDraw] = useState(false);

  //check token
  const [openTokenFailedPoppup, setOpenTokenFailedPoppup] = useState(false);
  let urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get("token");

  const { match_id } = useParams();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [matches, setMatches] = useState();
  const [match, setMatch] = useState(null);

  const [data, setData] = useState({
    match_date: null,
    match_location: null,
  });

  //myteam scores for game 1 , 2 and 3 myteam = user.team_id
  const [myteamscore1, setMyteamscore1] = useState();
  const [myteamscore2, setMyteamscore2] = useState();
  const [myteamscore3, setMyteamscore3] = useState();

  //other team score for game 1, 2 and 3
  const [otherteamscore1, setOtherteamscore1] = useState();
  const [otherteamscore2, setOtherteamscore2] = useState();
  const [otherteamscore3, setOtherteamscore3] = useState();
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnack2, setOpenSnack2] = useState(false);
  const [msg, setMsg] = useState();


  const getMatches = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-matches-for-update-score-page/" + user.team_id)
      .then((res) => {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        if (res.data.success) {
          console.log(res.data.matches);
          setMatches(res.data.matches);
          if (!extraMatch) {
            //if no match_id set match to first match in matches
            if (!match_id) {
              // setMatch(res.data.matches[0]);
              // setData({
              //   match_date: res.data.matches[0].match_date.slice(0, 10),
              //   match_location: res.data.matches[0].match_location,
              // });
            }
          }
        } else {
          setErrorMsg(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  
  const [match_date, setMatchDate] = useState();

  const getMatch = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-match/" + match_id)
      .then((res) => {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
        if (res.data.success) {
          console.log(res.data.match);
          setMatch(res.data.match);
          setData({
            match_date: res.data.match.match_date.slice(0, 10),
            match_location: res.data.match.match_location,
          });
          setMatchDate(res.data.match.match_date);
        } else {
          setErrorMsg(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitScore = async () => {
    if (!data.match_date || data.match_date.length < 1) {
      setErrorMsg("Match date feild is required");
      return;
    } else {
      setErrorMsg("");
    }
    if (!data.match_location || data.match_location.length < 1) {
      setErrorMsg("Match location feild is required");
      document.getElementById("locationf").style.border = "1px solid red";
      return;
    } else {
      setErrorMsg("");
    }
    if (
      (myteamscore1 < 11 && otherteamscore1 < 11) ||
      Math.abs(myteamscore1 - otherteamscore1) < 2
    ) {
      document.querySelectorAll(".game1").forEach(function (element) {
        element.style.border = "1px solid red";
      });

      document.querySelectorAll(".game3").forEach(function (element) {
        element.style.border = "1px solid #44A093";
      });

      document.querySelectorAll(".game2").forEach(function (element) {
        element.style.border = "1px solid #44A093";
      });

      setErrorMsg(
        "Error: Game 1 winning team should have at least 11 points and a score difference of > 2."
      );
      return;
    } else {
      document.querySelectorAll(".game2").forEach(function (element) {
        element.style.border = "1px solid #44A093";
      });
      setErrorMsg("");
    }

    if (
      (myteamscore2 < 11 && otherteamscore2 < 11) ||
      Math.abs(myteamscore2 - otherteamscore2) < 2
    ) {
      document.querySelectorAll(".game2").forEach(function (element) {
        element.style.border = "1px solid red";
      });

      document.querySelectorAll(".game3").forEach(function (element) {
        element.style.border = "1px solid #44A093";
      });

      document.querySelectorAll(".game1").forEach(function (element) {
        element.style.border = "1px solid #44A093";
      });

      setErrorMsg(
        "Error: Game 2 winning team should have at least 11 points and a score difference of > 2."
      );
      return;
    } else {
      document.querySelectorAll(".game2").forEach(function (element) {
        element.style.border = "1px solid #44A093";
      });
      setErrorMsg("");
    }

    //console.log(typeof myteamscore3);

    if (myteamscore3 != 0 && otherteamscore3 != 0) {
      if (
        (myteamscore3 < 11 && otherteamscore3 < 11) ||
        Math.abs(myteamscore3 - otherteamscore3) < 2
      ) {
        console.log("yes");
        document.querySelectorAll(".game3").forEach(function (element) {
          element.style.border = "1px solid red";
        });
        document.querySelectorAll(".game2").forEach(function (element) {
          element.style.border = "1px solid #44A093";
        });

        document.querySelectorAll(".game1").forEach(function (element) {
          element.style.border = "1px solid #44A093";
        });
        setErrorMsg(
          "Error: Game 3 winning team should have at least 11 points and a score difference of > 2.."
        );
        return;
      } else {
        console.log("hi");
        document.querySelectorAll(".game3").forEach(function (element) {
          element.style.border = "1px solid #44A093";
        });
        setErrorMsg("");
      }
    }

    setLoader(true);

    let otherTeamId =
      match.teams[0].team_id == user.team_id
        ? match.teams[1].team_id
        : match.teams[0].team_id;

    let team_id = user.team_id;

    let score = `{
        "game1": {
        "${team_id}" :  ${myteamscore1 || 0},
       "${otherTeamId}" :  ${otherteamscore1 || 0}
      },
     "game2": {
        "${team_id}" :  ${myteamscore2 || 0},
       "${otherTeamId}" :  ${otherteamscore2 || 0}
      },
      "game3": {
        "${team_id}" :  ${myteamscore3 || 0},
       "${otherTeamId}" :  ${otherteamscore3 || 0}
      }
    
    }`;

    if (myteamscore3 == 0 && otherteamscore3 == 0) {
      score = `{
            "game1": {
            "${team_id}" :  ${myteamscore1 || 0},
           "${otherTeamId}" :  ${otherteamscore1 || 0}
          },
         "game2": {
            "${team_id}" :  ${myteamscore2 || 0},
           "${otherTeamId}" :  ${otherteamscore2 || 0}
          }
        }`;
    } else {
      score = `{
            "game1": {
            "${team_id}" :  ${myteamscore1 || 0},
           "${otherTeamId}" :  ${otherteamscore1 || 0}
          },
         "game2": {
            "${team_id}" :  ${myteamscore2 || 0},
           "${otherTeamId}" :  ${otherteamscore2 || 0}
          },
          "game3": {
            "${team_id}" :  ${myteamscore3 || 0},
           "${otherTeamId}" :  ${otherteamscore3 || 0}
          }
        
        }`;
    }

    let score_ = JSON.parse(score);

    //get winning_team
    let newData = {
      ...data,
      score: score_,
      match_id: match._id,
      winning_team: null,
      user_id: user._id,
      league_id: user.league,
    };
    if (Number(myteamscore1) + Number(myteamscore2) >= 22) {
      newData = {
        ...data,
        score: score_,
        match_id: match._id,
        winning_team: team_id,
        user_id: user._id,
        league_id: user.league,
      };
    }

    if (Number(otherteamscore1) + Number(otherteamscore2) >= 22) {
      newData = {
        ...data,
        score: score_,
        match_id: match._id,
        winning_team: otherTeamId,
        user_id: user._id,
        league_id: user.league,
      };
    }

    if (
      Number(myteamscore3) >= 11 &&
      Number(myteamscore3) - Number(otherteamscore3) >= 2
    ) {
      newData = {
        ...data,
        score: score_,
        match_id: match._id,
        winning_team: team_id,
        user_id: user._id,
        league_id: user.league,
      };
    }

    if (
      Number(otherteamscore3) >= 11 &&
      Number(otherteamscore3) - Number(myteamscore3) >= 2
    ) {
      newData = {
        ...data,
        score: score_,
        match_id: match._id,
        winning_team: otherTeamId,
        user_id: user._id,
        league_id: user.league,
      };
    }

    //console.log(newData);
    //console.log(score);

    let sentData = newData;

    // match.proposed_match
    if (extraMatch) {
      console.log(newData);
      return 
      await axios
        .post(process.env.REACT_APP_BACKEND_URL + "/submit-score/", {
          ...newData,
          score_accepted: false,
          sender_team: user?.team_id,
        })
        .then((res) => {
          console.log(res);
          setOpenSnack2(true);
          setMsg(res.data.msg);
          setLoader(false);
          getMatch();
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    } else {
      await axios
        .post(process.env.REACT_APP_BACKEND_URL + "/submit-score/", {
          ...newData,
          score_accepted: true,
        })
        .then((res) => {
          console.log(res);
          setOpenSnack(true);
          setMsg(res.data.msg);
          setLoader(false);
          getMatch();
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  let [team1score, setTeam1score] = useState(0);
  let [team2score, setTeam2score] = useState(0);

  let team1score_ = 0;
  let team2score_ = 0;

  useEffect(() => {
    if (match) {
      let teams = match?.teams;
      let score = match?.score ? match?.score[0] : {};
      let otherTeamId =
        teams[0].team_id == user.team_id ? teams[1].team_id : teams[0].team_id;

      console.log({ user_id: user.team_id, other_team_id: otherTeamId });
      setMyteamscore1(score.game1 ? score?.game1[user.team_id] : 0);
      setOtherteamscore1(score.game1 ? score?.game1[otherTeamId] : 0);
      setMyteamscore2(score.game2 ? score?.game2[user.team_id] : 0);
      setOtherteamscore2(score.game2 ? score?.game2[otherTeamId] : 0);
      setMyteamscore3(score.game3 ? score?.game3[user.team_id] : 0);
      setOtherteamscore3(score.game3 ? score?.game3[otherTeamId] : 0);

      //check what team has won match
      for (const game in score) {
        if (score[game][user.team_id] === 11) {
          setTeam1score(team1score_++);
        }
        if (score[game][otherTeamId] === 11) {
          setTeam2score(team2score_++);
        }
      }
    }
  }, [match]);

  useEffect(() => {
    if (match_id) {
      getMatch();
    }
  }, [match_id]);

  //get all matches with users team_id
  useEffect(() => {
    getMatches();
  }, []);

  const [maxdate, setMaxdate] = useState();
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Format month and day to ensure they are two digits
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    setMaxdate(`${year}-${month}-${day}`);
  }, []);

  function determineMatchResult() {
    let myTeamWins = 0;
    let otherTeamWins = 0;
    let draw = false;
    setWin(null);
    setDraw(false);

    // Check if my team wins any game
    if (myteamscore1 >= 11 && myteamscore1 - otherteamscore1 >= 2) {
      myTeamWins++;
    }
    if (myteamscore2 >= 11 && myteamscore2 - otherteamscore2 >= 2) {
      myTeamWins++;
    }

    // Check if other team wins any game
    if (otherteamscore1 >= 11 && otherteamscore1 - myteamscore1 >= 2) {
      otherTeamWins++;
    }
    if (otherteamscore2 >= 11 && otherteamscore2 - myteamscore2 >= 2) {
      otherTeamWins++;
    }

    // Determine match result
    if (myTeamWins >= 2) {
      setWin(0);
    } else if (otherTeamWins >= 2) {
      setWin(1);
    } else if (
      (myteamscore1 >= 11 &&
        otherteamscore2 >= 11 &&
        Math.abs(myteamscore1 - otherteamscore1) >= 2 &&
        Math.abs(myteamscore2 - otherteamscore2) >= 2) ||
      (myteamscore2 >= 11 &&
        otherteamscore1 >= 11 &&
        Math.abs(myteamscore1 - otherteamscore1) >= 2 &&
        Math.abs(myteamscore2 - otherteamscore2) >= 2)
    ) {
      draw = true;
      setDraw(true);
    }

    if (!draw) {
      console.log("No winner yet");
    } else {
      console.log("Draw");
    }
  }

  useEffect(() => {
    determineMatchResult();
  }, [
    myteamscore1,
    myteamscore2,
    myteamscore3,
    otherteamscore1,
    otherteamscore2,
    otherteamscore3,
  ]);
  return (
    <>
      <TokenFailed
        open={openTokenFailedPoppup}
        setOpen={setOpenTokenFailedPoppup}
      />

      <ReactHelmet>
        <title>Submit Scores - Paddle Party</title>
      </ReactHelmet>
      <ScoreSubmittedModal open={openSnack} setOpen={setOpenSnack} />

      <ExtraMatchScoreSubmitedModal open={openSnack2} setOpen={setOpenSnack2} />
      <div className="bg-white rounded-[30px] lg:w-[700px] w-full py-4 my-8  ">
        <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
          Submit Scores{" "}
          {extraMatch  && "- Extra Match"}
        </div>

        <div className="lg:px-40 px-10 space-y-5   lg:overflow-auto">
          <div>
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              Select a {extraMatch ? 'Team' : 'Match'}
            </div>
          
             <div className="">
             <TextField
               value={match ? match._id : ""}
               onChange={(e) => {
                 if(e.target.value == 'extra'){
                   window.location.pathname =
                   "dashboard/submit-extra-match";
                 }
                else{
                 window.location.pathname =
                 "/dashboard/submit-scores/" + e.target.value;
                }
               }}
               select
               variant="outlined"
               fullWidth
               sx={{
                 border: "1px solid #44A093",
                 bgcolor: "rgba(68, 160, 147, 0.02)",
                 borderRadius: "5px",
                 "& .MuiOutlinedInput-notchedOutline": {
                   border: "none",
                 },
                 "&:hover .MuiOutlinedInput-notchedOutline": {
                   border: "none",
                 },
                 "& .MuiSelect-root": {
                   padding: "1px 2px",
                 },
               }}
             >
               {matches &&
                 matches.map((item, index) => {
                   return (
                     <MenuItem
                       value={item._id}
                       key={item._id}
                       defaultValue={true}
                     >
                       {item.match_name}
                     </MenuItem>
                   );
                 })}

              {user.league_data.on_demand && (
                 <MenuItem 
                 value={'extra'}
                 
                 >
                 Other - Log Extra Match
                 </MenuItem>
              )}
             </TextField>
           </div>

            {match?.winning_team ? (
              <div className="text-[#CC0000] my-2 text-[16px] ">
                {`Scores already submitted for your match (${match?.match_name}). You can view the scores below. If there is an error, please contact support. `}
              </div>
            ) : null}
          </div>

          <div>
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              What date was the matched played?
            </div>
            <div className="w-full">
              <InputBase
                id="datef"
                value={data?.match_date}
                onChange={(e) => {
                  setData({ ...data, match_date: e.target.value });
                }}
                type={"date"}
                sx={{
                  width: "100%",
                  border: "1px solid #44A093",
                  bgcolor: "rgba(68,160,147,0.02)",
                  borderRadius: "5px",
                  pl: 2,
                  py: "1px",
                  "&:focus": {
                    borderColor: "#44A093", // Set focus border color
                    outline: "none", // Remove default focus outline
                  },
                }}
                inputProps={{
                  max: maxdate, // Set the minimum date to today
                }}
                className="date-input"
              />
            </div>
          </div>

          <div>
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              Where was the match played?
            </div>
            <div className="">
              <InputBase
                id="locationf"
                sx={{
                  width: "100%",
                  border: "1px solid #44A093",
                  bgcolor: "rgba(68,160,147,0.02)",
                  borderRadius: "5px",
                  pl: 2,
                  py: "1px",
                }}
                value={data?.match_location}
                onChange={(e) => {
                  setData({
                    ...data,
                    match_location: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div>
            <div>
              <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                What was the score of the first game?
              </div>
              <div className="flex items-center justify-center space-x-4">
                <div className="">
                  <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                    Your Team
                  </div>
                  <InputBase
                    inputProps={{ inputMode: "decimal" }}
                    sx={{
                      width: "73px",
                      border: "1px solid #44A093",
                      borderRadius: "19px",
                      "& input": {
                        textAlign: "center",
                      },
                      height: "73px",
                      fontSize: "29px",
                      color: "#12AFC2",
                    }}
                    type={"number"}
                    onFocus={() => {
                      if (myteamscore1 == 0) {
                        setMyteamscore1("");
                      }
                    }}
                    value={myteamscore1}
                    className="game1"
                    onChange={(e) => {
                      setMyteamscore1(e.target.value);
                      setMyteamscore3(0);
                      setOtherteamscore3(0);
                    }}
                  />
                </div>
                <div className="">
                  <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                    Other Team
                  </div>
                  <InputBase
                    inputProps={{ inputMode: "decimal" }}
                    sx={{
                      width: "73px",
                      border: "1px solid #44A093",
                      borderRadius: "19px",
                      "& input": {
                        textAlign: "center",
                      },
                      height: "73px",
                      fontSize: "29px",
                      color: "#12AFC2",
                    }}
                    type={"number"}
                    value={otherteamscore1}
                    onFocus={() => {
                      if (otherteamscore1 == 0) {
                        setOtherteamscore1("");
                      }
                    }}
                    className="game1"
                    onChange={(e) => {
                      setOtherteamscore1(e.target.value);
                      setMyteamscore3(0);
                      setOtherteamscore3(0);
                    }}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                What was the score of the second game?
              </div>
              <div className="flex items-center justify-center space-x-4">
                <div className="">
                  <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                    Your Team
                  </div>
                  <InputBase
                    inputProps={{ inputMode: "decimal" }}
                    sx={{
                      width: "73px",
                      border: "1px solid #44A093",
                      borderRadius: "19px",
                      "& input": {
                        textAlign: "center",
                      },
                      height: "73px",
                      fontSize: "29px",
                      color: "#12AFC2",
                    }}
                    type={"number"}
                    value={myteamscore2}
                    className="game2"
                    onFocus={() => {
                      if (myteamscore2 == 0) {
                        setMyteamscore2("");
                      }
                    }}
                    onChange={(e) => {
                      setMyteamscore2(e.target.value);
                      setMyteamscore3(0);
                      setOtherteamscore3(0);
                      setFocus(true);
                    }}
                    onBlur={() => {
                      setFocus(false);
                    }}
                  />
                </div>
                <div className="">
                  <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                    Other Team
                  </div>
                  <InputBase
                    inputProps={{ inputMode: "decimal" }}
                    sx={{
                      width: "73px",
                      border: "1px solid #44A093",
                      borderRadius: "19px",
                      "& input": {
                        textAlign: "center",
                      },
                      height: "73px",
                      fontSize: "29px",
                      color: "#12AFC2",
                    }}
                    type={"number"}
                    value={otherteamscore2}
                    className="game2"
                    onFocus={() => {
                      if (otherteamscore2 == 0) {
                        setOtherteamscore2("");
                      }
                    }}
                    onChange={(e) => {
                      setOtherteamscore2(e.target.value);
                      setMyteamscore3(0);
                      setOtherteamscore3(0);
                      setFocus(true);
                    }}
                    onBlur={() => {
                      setFocus(false);
                    }}
                  />
                </div>
              </div>
            </div>

            {draw && !focus_ && (
              <div>
                <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                  What was the score of the third game?
                </div>
                <div className="flex items-center justify-center space-x-4">
                  <div className="">
                    <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                      Your Team
                    </div>
                    <InputBase
                      inputProps={{ inputMode: "decimal" }}
                      sx={{
                        width: "73px",
                        border: "1px solid #44A093",
                        borderRadius: "19px",
                        "& input": {
                          textAlign: "center",
                        },
                        height: "73px",
                        fontSize: "29px",
                        color: "#12AFC2",
                      }}
                      type={"number"}
                      value={myteamscore3}
                      className="game3"
                      onFocus={() => {
                        if (myteamscore3 == 0) {
                          setMyteamscore3("");
                        }
                      }}
                      onChange={(e) => {
                        setMyteamscore3(e.target.value);
                      }}
                    />
                  </div>
                  <div className="">
                    <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                      Other Team
                    </div>
                    <InputBase
                      inputProps={{ inputMode: "decimal" }}
                      sx={{
                        width: "73px",
                        border: "1px solid #44A093",
                        borderRadius: "19px",
                        "& input": {
                          textAlign: "center",
                        },
                        height: "73px",
                        fontSize: "29px",
                        color: "#12AFC2",
                      }}
                      type={"number"}
                      value={otherteamscore3}
                      className="game3"
                      onFocus={() => {
                        if (otherteamscore3 == 0) {
                          setOtherteamscore3("");
                        }
                      }}
                      onChange={(e) => {
                        setOtherteamscore3(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {win == 0 && (
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              Winning Team:{" "}
              {user.team_id === match.teams[0].team_id &&
                match.teams[0].team_name}
              {user.team_id === match.teams[1].team_id &&
                match.teams[1].team_name}
            </div>
          )}

          {win == 1 && (
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              Winning Team:{" "}
              {user.team_id != match.teams[0].team_id &&
                match.teams[0].team_name}
              {user.team_id != match.teams[1].team_id &&
                match.teams[1].team_name}
            </div>
          )}

          {Number(myteamscore3) >= 11 &&
            Number(myteamscore3) - Number(otherteamscore3) >= 2 && (
              <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                Winning Team:{" "}
                {user.team_id === match.teams[0].team_id &&
                  match.teams[0].team_name}
                {user.team_id === match.teams[1].team_id &&
                  match.teams[1].team_name}
              </div>
            )}

          {Number(otherteamscore3) >= 11 &&
            Number(otherteamscore3) - Number(myteamscore3) >= 2 && (
              <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
                Winning Team:{" "}
                {user.team_id != match.teams[0].team_id &&
                  match.teams[0].team_name}
                {user.team_id != match.teams[1].team_id &&
                  match.teams[1].team_name}
              </div>
            )}

          {/* {match && match.winning_team && (
            <div className=" lg:text-[18px] text-[14px]  my-3 text-[#244F62D9] font-[400]">
              Winning Team:{" "}
              {match.winning_team === match.teams[0].team_id &&
                match.teams[0].team_name}
              {match.winning_team === match.teams[1].team_id &&
                match.teams[1].team_name}
            </div>
          )} */}

          <div className="text-[#CC0000] my-2 text-[16px] ">{errorMsg}</div>

          <div className=" flex justify-center mt-[50px] ">
            {match?.winning_team ? (
              <Button
                sx={{
                  bgcolor: "#44A093",
                  color: "white",
                  width: "150px",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "15px",
                  ":hover": {
                    bgcolor: "#44A093",
                  },
                }}
                onClick={() => {
                  window.location.href = `mailto:hello@paddleparty.com`;
                }}
              >
                Contact Support
              </Button>
            ) : (
              <Button
                sx={{
                  bgcolor: "#44A093",
                  color: "white",
                  width: "150px",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "15px",
                  ":hover": {
                    bgcolor: "#44A093",
                  },
                }}
                onClick={submitScore}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateScores;
