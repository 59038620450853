import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './dashboard/Layout'
import Login from './screens/Login'

const Main = () => {
  return (
   <>
    <BrowserRouter>
            <Routes>
              <Route path="/:token" element={<Login/>} />
              <Route path="/" element={<Login/>} />
              <Route path="/dashboard/*" element={<Layout/>} />
            </Routes>
          </BrowserRouter>
   </>
  )
}

export default Main